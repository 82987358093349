import React from 'react';

function StickerForm2(props) {

    const [unitPrice, setUnitPrice] = React.useState(5);
    const [w, setW] = React.useState(5);
    const [h, setH] = React.useState(5);
    const [WH, setWH] = React.useState(5);
    const [x, setX] = React.useState(1);
    const [kinds, setKinds] = React.useState(1);
    const [print, setPrint] = React.useState(1);
    const [material, setMaterial] = React.useState(1);
    const [lamination, setLamination] = React.useState(1);
    const [slicing, setSlicing] = React.useState(1);
    const [hard, setHard] = React.useState(false);

    function handleChangeW(e) {
        setW(e.target.value);
        const newWH = (h*e.target.value) / ((Number(h)+Number(e.target.value))/2);
        setWH(newWH);
        setUnitPrice(newWH*unitPrice/WH)
    }

    function handleChangeH(e) {
        setH(e.target.value);
        const newWH = (w*e.target.value) / ((Number(w)+Number(e.target.value))/2)
        setWH(newWH);
        setUnitPrice(newWH*unitPrice/WH)
    }

    function handleChangeX(e) {
        setX(e.target.value);
    }

    function handleChangeKinds(e) {
        setKinds(e.target.value);
        setUnitPrice(e.target.value*unitPrice/kinds)
    }

    function handleChangePrint(e) {
        setPrint(e.target.value);
        setUnitPrice(e.target.value*unitPrice/print)
    }

    function handleChangeMaterial(e) {
        setMaterial(e.target.value);
        setUnitPrice(e.target.value*unitPrice/material)
    }

    function handleChangeLamination(e) {
        setLamination(e.target.value);
        setUnitPrice(e.target.value*unitPrice/lamination)
    }

    function handleChangeSlicing(e) {
        setSlicing(e.target.value);
        setUnitPrice(e.target.value*unitPrice/slicing)
    }

    function handleChangeHard(e) {
        if (hard) {
            setHard(false)
            setUnitPrice(unitPrice/1.5)
        } else {
            setHard(true)
            setUnitPrice(1.5*unitPrice)
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        props.setTotal(props.total + Math.round(unitPrice)*x)

        let pri = ''
        let mat = ''
        let lam = ''
        let sli = ''

        if (props.data === 'Гарантийные стикеры' 
          || props.data === 'Гарантийные этикетки' 
          || props.data === 'Объемные стикеры' 
          || props.data === 'Обьемные этикетки'
          || props.data === 'Обратные стикеры'
          || props.data === 'Графика на стену'
          || props.data === 'Графика на пол'
          || props.data === 'Наклейки на машину'
          || props.data === 'Наклейки на монтажной пленке'
          ) {
            pri = ''
          } else if (Number(print) === 1) {
            pri = 'Струйная экосольвентная'
          } else if (Number(print) === 1.1) {
            pri = 'Лазерная печать'
          } else {
            pri = 'УФ печать (белым цветом)'
          }

        if (props.data === 'Графика на пол'
          || props.data === 'Обратные стикеры'
          || props.data === 'Объемные стикеры' 
          || props.data === 'Обьемные этикетки'
          || props.data === 'Гарантийные стикеры' 
          || props.data === 'Гарантийные этикетки'
          ) {
            mat = ''
          } else if (Number(material) === 1) {
            mat = 'белая матовая пленка'
          } else if (Number(material) === 1.1) {
            mat = 'белая глянцевая пленка'
          } else {
            mat = 'прозрачная матовая пленка'
          }

        if (props.data === 'Обратные стикеры'
          || props.data === 'Объемные стикеры' 
          || props.data === 'Обьемные этикетки'
          || props.data === 'Гарантийные стикеры' 
          || props.data === 'Гарантийные этикетки' 
          || props.data === 'Черно-белые этикетки'
          ) {
            lam = ''
          } else if (Number(lamination) === 1) {
            lam = 'без ламинации'
          } else if (Number(lamination) === 1.1) {
            lam = 'Матовая (25 мкр)'
          } else {
            lam = 'Глянцевая (25 мкр)'
          }

        if (props.data === 'Наклейки на монтажной пленке'
          || props.data === 'Графика на пол'
          || props.data === 'Графика на стену'
          || props.data === 'Гарантийные стикеры' 
          || props.data === 'Гарантийные этикетки'
          || props.data === 'Черно-белые этикетки'
          ) {
            sli = ''
          } else if (Number(slicing) === 1) {
            sli = 'на общей подложке 300х800 мм'
          } else if (Number(slicing) === 1.1) {
            sli = 'на общей подложке 330х487 мм'
          } else {
            sli = 'ручная нарезка'
          }

        let har = ''
        if(hard) {
            har = 'Сложная форма'
        }
          
          if(props.purchases.some((item)=>{return item.info === w+'х'+h+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+sli+har+props.data && item.name === props.name})) {
            let a = props.purchases.find((item)=>{return item.info === w+'х'+h+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+sli+har+props.data && item.name === props.name})
            a.x = a.x+1
            return;
          }

        props.setPurchases([...props.purchases, {
            name: props.name,
            info: w+'х'+h+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+sli+har+props.data,
            x: 1,
            price: Math.round(unitPrice)*x,
        }])  
    }

    React.useEffect(() => {
        setUnitPrice(5);
        setW(5);
        setH(5);
        setWH(5);
        setX(1);
        setKinds(1);
        setPrint(1);
        setMaterial(1);
        setLamination(1);
        setSlicing(1);
        setHard(false);
        document.getElementsByClassName('constructor__form')[0].reset();
    }, [props.data]);

    return (
        <>
            { props.data === 'Наклейки на монтажной пленке'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">белая матовая пленка</option>
                            <option value="1.1">белая глянцевая пленка</option>
                            <option value="1.2">прозрачная матовая пленка</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">без ламинации</option>
                            <option value="1.1">Матовая (25 мкр)</option>
                            <option value="1.2">Глянцевая (25 мкр)</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            : props.data === 'Наклейки на машину'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">белая матовая пленка</option>
                            <option value="1.1">белая глянцевая пленка</option>
                            <option value="1.2">прозрачная матовая пленка</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">без ламинации</option>
                            <option value="1.1">Матовая (25 мкр)</option>
                            <option value="1.2">Глянцевая (25 мкр)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Нарезка
                        <select className='constructor__form-select' name='slicing' onChange={handleChangeSlicing}>
                            <option value="1">на общей подложке 300х800 мм</option>
                            <option value="1.1">на общей подложке 330х487 мм</option>
                            <option value="1.2">ручная нарезка</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            : props.data === 'Графика на пол'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">без ламинации</option>
                            <option value="1.1">Матовая (25 мкр)</option>
                            <option value="1.2">Глянцевая (25 мкр)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Сложная форма
                        <input className='constructor__form-input_checkbox' type='checkbox' name='hard' onChange={handleChangeHard} value={hard}></input>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            : props.data === 'Графика на стену'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">белая матовая пленка</option>
                            <option value="1.1">белая глянцевая пленка</option>
                            <option value="1.2">прозрачная матовая пленка</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">без ламинации</option>
                            <option value="1.1">Матовая (25 мкр)</option>
                            <option value="1.2">Глянцевая (25 мкр)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Сложная форма
                        <input className='constructor__form-input_checkbox' type='checkbox' name='hard' onChange={handleChangeHard} value={hard}></input>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            : props.data === 'Обратные стикеры'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Кол-во видов
                        <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeKinds}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Нарезка
                        <select className='constructor__form-select' name='slicing' onChange={handleChangeSlicing}>
                            <option value="1">на общей подложке 300х800 мм</option>
                            <option value="1.1">на общей подложке 330х487 мм</option>
                            <option value="1.2">ручная нарезка</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Сложная форма
                        <input className='constructor__form-input_checkbox' type='checkbox' name='hard' onChange={handleChangeHard} value={hard}></input>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            : props.data === 'Объемные стикеры' || props.data === 'Обьемные этикетки'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Кол-во видов
                        <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeKinds}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Нарезка
                        <select className='constructor__form-select' name='slicing' onChange={handleChangeSlicing}>
                            <option value="1">на общей подложке 300х800 мм</option>
                            <option value="1.1">на общей подложке 330х487 мм</option>
                            <option value="1.2">ручная нарезка</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Сложная форма
                        <input className='constructor__form-input_checkbox' type='checkbox' name='hard' onChange={handleChangeHard} value={hard}></input>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            : props.data === 'Гарантийные стикеры' || props.data === 'Гарантийные этикетки'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Кол-во видов
                        <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeKinds}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            : props.data === 'Черно-белые этикетки'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Кол-во видов
                        <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeKinds}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Печать
                        <select className='constructor__form-select' name='print' onChange={handleChangePrint}>
                            <option value="1">Струйная экосольвентная</option>
                            <option value="1.1">Лазерная печать</option>
                            <option value="1.2">УФ печать (белым цветом)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">белая матовая пленка</option>
                            <option value="1.1">белая глянцевая пленка</option>
                            <option value="1.2">прозрачная матовая пленка</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            :
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                        <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                        <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                        <p className='constructor__form-input-multiplier'>X</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Кол-во видов
                        <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeKinds}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Печать
                        <select className='constructor__form-select' name='print' onChange={handleChangePrint}>
                            <option value="1">Струйная экосольвентная</option>
                            <option value="1.1">Лазерная печать</option>
                            <option value="1.2">УФ печать (белым цветом)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">белая матовая пленка</option>
                            <option value="1.1">белая глянцевая пленка</option>
                            <option value="1.2">прозрачная матовая пленка</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">без ламинации</option>
                            <option value="1.1">Матовая (25 мкр)</option>
                            <option value="1.2">Глянцевая (25 мкр)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Нарезка
                        <select className='constructor__form-select' name='slicing' onChange={handleChangeSlicing}>
                            <option value="1">на общей подложке 300х800 мм</option>
                            <option value="1.1">на общей подложке 330х487 мм</option>
                            <option value="1.2">ручная нарезка</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            }
        </>
    );
  }
  
  export default StickerForm2;