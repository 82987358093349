import React from 'react';

function CalendarForm(props) {

    const [unitPrice, setUnitPrice] = React.useState(5);
    const [WH, setWH] = React.useState(5);
    const [x, setX] = React.useState(50);
    const [finishing, setFinishing] = React.useState(1);
    const [advertising, setAdvertising] = React.useState(1);
    const [design, setDesign] = React.useState(1);
    const [block, setBlock] = React.useState(1);
    

    function handleChangeWH(e) {
        setWH(e.target.value);
        setUnitPrice(e.target.value*unitPrice/WH)
        if (e.target.value === '5') {
            props.setW(70)
            props.setH(99)
        } else if (e.target.value === '6') {
            props.setW(80)
            props.setH(109)
        } else {
            props.setW(90)
            props.setH(119)
        }
      }

    function handleChangeX(e) {
        setX(e.target.value);
    }

    function handleChangeFinishing(e) {
        setFinishing(e.target.value);
        setUnitPrice(e.target.value*unitPrice/finishing)
    }

    function handleChangeAdvertising(e) {
        setAdvertising(e.target.value);
        setUnitPrice(e.target.value*unitPrice/advertising)
    }

    function handleChangeDesign(e) {
        setDesign(e.target.value);
        setUnitPrice(e.target.value*unitPrice/design)
    }

    function handleChangeBlock(e) {
        setBlock(e.target.value);
        setUnitPrice(e.target.value*unitPrice/block)
    }

    function handleSubmit(e) {
        e.preventDefault()
        props.setTotal(props.total + Math.round(unitPrice)*x)

        let fin = ''
        let adv = ''
        let des = ''
        let blo = ''

        if (Number(finishing) === 1) {
            fin = 'Без отделки'
          } else if (Number(finishing) === 1.1) {
            fin = 'Глянцевая ламинация 1+0 (25 мкр)'
          } else {
            fin = 'Матовая ламинация 1+0 (25 мкр)'
          }


        if (props.data !== 'Квартальные календари на 3-х пружинах' || props.data !== 'Квартальные календари на 1 пружине') {
            adv = ''
        } else if (Number(advertising) === 1) {
            adv = 'без рекламных полей'
          } else if (Number(advertising) === 1.1) {
            adv = 'одно рекламное поле'
          } else {
            adv = 'три рекламных поля'
          }

        if (props.data !== 'Квартальные календари на 3-х пружинах' 
          || props.data !== 'Квартальные календари на 1 пружине' 
          || props.data !== 'Календари домики перекидные'
          ) {
            des = ''
          } else if (Number(design) === 1) {
            des = 'Не выбрано'
          } else if (Number(design) === 1.1) {
            des = 'ЕВРОПА s-металлик макси (3 х 370*170) голубой металлик'
          } else {
            des = 'ЕВРОПА металлик макси (3 х 370*170) серебристо-белый'
          }

          if (props.data === 'Календари домики самосборные' 
          || props.data === 'Карманные календари'
          || props.data === 'Квартальные календари на 3-х пружинах' 
          || props.data === 'Квартальные календари на 1 пружине'
          ) {
            blo = ''
          } else if (Number(block) === 1) {
            blo = 'блок 7 листов (индивид)'
          } else if (Number(block) === 1.1) {
            blo = 'блок 12 листов (полимат)'
          } else {
            blo = 'блок 12 листов (полимат)'
          }
          
          if(props.purchases.some((item)=>{return item.info === WH+x+' шт'+props.data+fin+adv+des+blo && item.name === props.name})) {
            let a = props.purchases.find((item)=>{return item.info === WH+x+' шт'+props.data+fin+adv+des+blo && item.name === props.name})
            a.x = a.x+1
            return;
          }

        props.setPurchases([...props.purchases, {
            name: props.name,
            info: WH+x+' шт'+props.data+fin+adv+des+blo,
            x: 1,
            price: Math.round(unitPrice)*x,
        }])  
    }

    React.useEffect(() => {
        setUnitPrice(5);
        setWH(5);
        setX(50);
        setFinishing(1);
        setAdvertising(1);
        setDesign(1);
        setBlock(1);
        document.getElementsByClassName('constructor__form')[0].reset();
    }, [props.data]);

    return (
        <>
        { props.data === 'Квартальные календари на 3-х пружинах' || props.data === 'Квартальные календари на 1 пружине'
        ?
        <form className="constructor__form" onSubmit={handleSubmit}>
            <div className="constructor__form-inputs">
                <label className='constructor__form-label'>
                    Размер
                    <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeWH}>
                        <option value="5">Мини (297x210 мм)</option>
                        <option value="6">Миди (335х250 мм)</option>
                        <option value="7">Макси (370х290 мм)</option>
                    </select>
                </label>
                
                <label className='constructor__form-label'>
                    Отделка
                    <select className='constructor__form-select' name='print' onChange={handleChangeFinishing}>
                        <option value="1">Без отделки</option>
                        <option value="1.1">Глянцевая ламинация 1+0 (25 мкр)</option>
                        <option value="1.2">Матовая ламинация 1+0 (25 мкр)</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Реламные поля
                    <select className='constructor__form-select' name='material' onChange={handleChangeAdvertising}>
                        <option value="1">без рекламных полей</option>
                        <option value="1.1">одно рекламное поле</option>
                        <option value="1.2">три рекламных поля</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Дизайн блока
                    <select className='constructor__form-select' name='lamination' onChange={handleChangeDesign}>
                        <option value="1">Не выбрано</option>
                        <option value="1.1">ЕВРОПА s-металлик макси (3 х 370*170) голубой металлик</option>
                        <option value="1.2">ЕВРОПА металлик макси (3 х 370*170) серебристо-белый</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Тираж
                    <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeX}>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                    </select>
                </label>
            </div>
            <div className='constructor__form-submit-container'>
                <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                <button type='submit' className='constructor__form-submit'>
                    Добавить в корзину</button>
                <p className='constructor__form-link' href='#'>Нужен дизайн календаря?</p>
            </div>
        </form>
        : props.data === 'Календари домики перекидные'
        ?
        <form className="constructor__form" onSubmit={handleSubmit}>
            <div className="constructor__form-inputs">
                <label className='constructor__form-label'>
                    Размер
                    <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeWH}>
                        <option value="5">Мини (297x210 мм)</option>
                        <option value="6">Миди (335х250 мм)</option>
                        <option value="7">Макси (370х290 мм)</option>
                    </select>
                </label>
                
                <label className='constructor__form-label'>
                    Отделка
                    <select className='constructor__form-select' name='print' onChange={handleChangeFinishing}>
                        <option value="1">Без отделки</option>
                        <option value="1.1">Глянцевая ламинация 1+0 (25 мкр)</option>
                        <option value="1.2">Матовая ламинация 1+0 (25 мкр)</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Блок
                    <select className='constructor__form-select' name='material' onChange={handleChangeBlock}>
                        <option value="1">блок 7 листов (индивид)</option>
                        <option value="1.1">блок 12 листов (полимат)</option>
                        <option value="1.2">блок 12 листов (полимат)</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Дизайн блока
                    <select className='constructor__form-select' name='lamination' disabled ={Number(block) === 1 || Number(block) === 1.2 ? true : false} onChange={handleChangeDesign}>
                        <option value="1">Не выбрано</option>
                        <option value="1.1">ЕВРОПА s-металлик макси (3 х 370*170) голубой металлик</option>
                        <option value="1.2">ЕВРОПА металлик макси (3 х 370*170) серебристо-белый</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Тираж
                    <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeX}>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                    </select>
                </label>
            </div>
            <div className='constructor__form-submit-container'>
                <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                <button type='submit' className='constructor__form-submit'>
                    Добавить в корзину</button>
                <p className='constructor__form-link' href='#'>Нужен дизайн календаря?</p>
            </div>
        </form>
        : props.data === 'Календари домики самосборные' || props.data === 'Карманные календари'
        ?
        <form className="constructor__form" onSubmit={handleSubmit}>
            <div className="constructor__form-inputs">
                <label className='constructor__form-label'>
                    Размер
                    <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeWH}>
                        <option value="5">Мини (297x210 мм)</option>
                        <option value="6">Миди (335х250 мм)</option>
                        <option value="7">Макси (370х290 мм)</option>
                    </select>
                </label>
                
                <label className='constructor__form-label'>
                    Отделка
                    <select className='constructor__form-select' name='print' onChange={handleChangeFinishing}>
                        <option value="1">Без отделки</option>
                        <option value="1.1">Глянцевая ламинация 1+0 (25 мкр)</option>
                        <option value="1.2">Матовая ламинация 1+0 (25 мкр)</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Тираж
                    <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeX}>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                    </select>
                </label>
            </div>
            <div className='constructor__form-submit-container'>
                <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                <button type='submit' className='constructor__form-submit'>
                    Добавить в корзину</button>
                <p className='constructor__form-link' href='#'>Нужен дизайн календаря?</p>
            </div>
        </form>
        :
        <form className="constructor__form" onSubmit={handleSubmit}>
            <div className="constructor__form-inputs">
                <label className='constructor__form-label'>
                    Размер
                    <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeWH}>
                        <option value="5">Мини (297x210 мм)</option>
                        <option value="6">Миди (335х250 мм)</option>
                        <option value="7">Макси (370х290 мм)</option>
                    </select>
                </label>
                
                <label className='constructor__form-label'>
                    Отделка
                    <select className='constructor__form-select' name='print' onChange={handleChangeFinishing}>
                        <option value="1">Без отделки</option>
                        <option value="1.1">Глянцевая ламинация 1+0 (25 мкр)</option>
                        <option value="1.2">Матовая ламинация 1+0 (25 мкр)</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Блок
                    <select className='constructor__form-select' name='material' onChange={handleChangeBlock}>
                        <option value="1">блок 7 листов (индивид)</option>
                        <option value="1.1">блок 12 листов (полимат)</option>
                        <option value="1.2">блок 12 листов (полимат)</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Тираж
                    <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeX}>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                    </select>
                </label>
            </div>
            <div className='constructor__form-submit-container'>
                <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                <button type='submit' className='constructor__form-submit'>
                    Добавить в корзину</button>
                <p className='constructor__form-link' href='#'>Нужен дизайн календаря?</p>
            </div>
        </form>
        }
        </>
    );
  }
  
  export default CalendarForm;