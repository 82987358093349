import React from 'react';
import { useLocation } from 'react-router-dom';

function StickerForm3(props) {

    const [unitPrice, setUnitPrice] = React.useState(5);
    const [WH, setWH] = React.useState(5);
    const [x, setX] = React.useState(1);
    const [kinds, setKinds] = React.useState(1);
    const [print, setPrint] = React.useState(1);
    const [material, setMaterial] = React.useState(1);
    const [lamination, setLamination] = React.useState(1);

    const url = useLocation().pathname;

    function handleChangeWH(e) {
        setWH(e.target.value);
        setUnitPrice(e.target.value*unitPrice/WH)
      }

    function handleChangeX(e) {
        setX(e.target.value);
    }

    function handleChangeKinds(e) {
        setKinds(e.target.value);
        setUnitPrice(e.target.value*unitPrice/kinds)
    }

    function handleChangePrint(e) {
        setPrint(e.target.value);
        setUnitPrice(e.target.value*unitPrice/print)
    }

    function handleChangeMaterial(e) {
        setMaterial(e.target.value);
        setUnitPrice(e.target.value*unitPrice/material)
    }

    function handleChangeLamination(e) {
        setLamination(e.target.value);
        setUnitPrice(e.target.value*unitPrice/lamination)
    }

    function handleSubmit(e) {
        e.preventDefault()
        props.setTotal(props.total + Math.round(unitPrice)*x)

        let pri = ''
        let mat = ''
        let lam = ''

        if (props.data === 'Простые наклейки на бумаге'
          || url === "/pechat-plakatov" 
          || url === "/pechat-chertezhej"
          ) {
            pri = ''
          } else if (Number(print) === 1) {
            pri = 'Струйная экосольвентная'
          } else if (Number(print) === 1.1) {
            pri = 'Лазерная печать'
          } else {
            pri = 'УФ печать (белым цветом)'
          }

        if (props.data === 'Простые наклейки на бумаге') {
            mat = ''
          } else if (Number(material) === 1) {
            mat = 'белая матовая пленка'
          } else if (Number(material) === 1.1) {
            mat = 'белая глянцевая пленка'
          } else {
            mat = 'прозрачная матовая пленка'
          }

        if (props.data === 'Простые наклейки на бумаге'
          || url === "/pechat-plakatov" 
          || url === "/pechat-chertezhej"
          ) {
            lam = ''
          } else if (Number(lamination) === 1) {
            lam = 'без ламинации'
          } else if (Number(lamination) === 1.1) {
            lam = 'Матовая (25 мкр)'
          } else {
            lam = 'Глянцевая (25 мкр)'
          }
          
          if(props.purchases.some((item)=>{return item.info === WH+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+props.data && item.name === props.name})) {
            let a = props.purchases.find((item)=>{return item.info === WH+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+props.data && item.name === props.name})
            a.x = a.x+1
            return;
          }

        props.setPurchases([...props.purchases, {
            name: props.name,
            info: WH+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+props.data,
            x: 1,
            price: Math.round(unitPrice)*x,
        }])  
    }

    React.useEffect(() => {
        setUnitPrice(5);
        setWH(5);
        setX(1);
        setKinds(1);
        setPrint(1);
        setMaterial(1);
        setLamination(1);
        document.getElementsByClassName('constructor__form')[0].reset();
    }, [props.data]);

    return (
        <>
        { url === "/pechat-nakleek"
        ? <>
            { props.data === 'Простые наклейки на бумаге'
            ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeWH}>
                            <option value="5">A6 (105х148)</option>
                            <option value="6">A5 (148х210)</option>
                            <option value="7">A4 (210х297)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Кол-во видов
                        <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeKinds}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            :
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeWH}>
                            <option value="5">A6 (105х148)</option>
                            <option value="6">A5 (148х210)</option>
                            <option value="7">A4 (210х297)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Кол-во видов
                        <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeKinds}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Печать
                        <select className='constructor__form-select' name='print' onChange={handleChangePrint}>
                            <option value="1">Струйная экосольвентная</option>
                            <option value="1.1">Лазерная печать</option>
                            <option value="1.2">УФ печать (белым цветом)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">белая матовая пленка</option>
                            <option value="1.1">белая глянцевая пленка</option>
                            <option value="1.2">прозрачная матовая пленка</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">без ламинации</option>
                            <option value="1.1">Матовая (25 мкр)</option>
                            <option value="1.2">Глянцевая (25 мкр)</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
            } 
        </>
        : url === "/pechat-plakatov" || url === "/pechat-chertezhej"
        ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Размер (ШхВ)
                        <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeWH}>
                            <option value="5">A6 (105х148)</option>
                            <option value="6">A5 (148х210)</option>
                            <option value="7">A4 (210х297)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">белая матовая пленка</option>
                            <option value="1.1">белая глянцевая пленка</option>
                            <option value="1.2">прозрачная матовая пленка</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн баннера?</p>
                </div>
            </form> 
        :
        <h1>no</h1>
        }
        </>
    );
  }
  
  export default StickerForm3;