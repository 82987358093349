import Nav from "../Nav/Nav";
import Designer from "../Designer/Designer";
import React from 'react';

function FirstCalculator(props) {

    return (
      <>
        <Nav name={props.name} />
        <Designer total={props.total} setTotal={props.setTotal} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
      </>
    );
  }
  
  export default FirstCalculator;