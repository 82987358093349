import print from '../../images/print.svg'
import paper from '../../images/paper.svg'
import pen from '../../images/pen.svg'
import pdf from '../../images/01-pdf.svg'
import ai from '../../images/02-ai.svg'
import eps from '../../images/03-eps.svg'
import cdr from '../../images/04-cdr.svg'
import tiff from '../../images/05-tiff.svg'
import jpg from '../../images/06-jpg.svg'
import png from '../../images/07-png.svg'
import React from 'react';
import { useLocation } from 'react-router-dom';

function Designer (props) {

    const [prices, setPrices] = React.useState([500,600,700]);
    const [price, setPrice] = React.useState(500);
    const [corner, setСorner] = React.useState(false);
    const [selectedPrice, setSelectedPrice] = React.useState(0);
    const [selectedPriceBlock, setSelectedPriceBlock] = React.useState("");
    const [navBlock, setNavBlock] = React.useState("");
    const [navBlockValue, setNavBlockValue] = React.useState(1);

    const [select, setSelect] = React.useState(1);
    const [X, setX] = React.useState('');
    const [type, setType] = React.useState('');

    function handleChangeСorner(e) {
        setСorner(e.target.checked);  
        corner ? setPrice(price-100) : setPrice(price+100);
    }

    function handleChangeSelect (e) {
      corner ? setPrice(prices[e.target.value]+100) : setPrice(prices[e.target.value]);
      setSelect(e.target.value);
    }

    function handleSelectPrice (e) {
      if(selectedPriceBlock){
        selectedPriceBlock.classList.toggle('designer__tab-table-td_active');
      }
      e.target.classList.toggle('designer__tab-table-td_active');
      setSelectedPriceBlock(e.target);
      setSelectedPrice(e.target.getAttribute('value'));

      setX(e.target.getAttribute('x'))
      setType(e.target.getAttribute('types'))
    }

    function handleChangeNavBlock(e) {
      if(e.target !== navBlock) {
        e.target.classList.toggle('designer__nav-btn_active')
        navBlock.classList.toggle('designer__nav-btn_active')
        setNavBlock(e.target)
        setNavBlockValue(Number(e.target.getAttribute('value')))
        if (Number(e.target.getAttribute('value'))=== 1) {
          setPrices ([500,600,700])
          corner ? setPrice(600) : setPrice(500)
        } else {
          setPrices ([3000,6000])
          corner ? setPrice(3100) : setPrice(3000)
        }
        if(selectedPriceBlock){
          selectedPriceBlock.classList.toggle('designer__tab-table-td_active');
          setSelectedPriceBlock('')
          setSelectedPrice(0)
        }
      }
    }

    function handleAddToCart () {
      if (!selectedPriceBlock) {
        return;
      }

      props.setTotal(props.total + Number(selectedPrice));
      
      let paper = ''
      if (navBlockValue === 1) {
        if (select === 0) {
          paper = 'Мелованная бумага без отделки'
        } else if (select === 1) {
          paper = 'Мелованная бумага ламинация матовая (75 микрона)'
        } else {
          paper = 'Дизайнерская бумага "Лён"'
        }
      } else {
        if (select === 0) {
          paper = 'Мелованный картон без отделки'
        } else {
          paper = 'Мелованный картон глянцевая ламинация'
        }
      }

      let corn = ''
      if(corner) {
        corn = '+ скругление углов'
      }

      if(props.purchases.some((item)=>{return item.info === paper+", "+type+", "+X+corn && item.name === props.name})) {
        let a = props.purchases.find((item)=>{return item.info === paper+", "+type+", "+X+corn && item.name === props.name})
        a.x = a.x+1
        return;
      }
      
      props.setPurchases([...props.purchases, {
        name: props.name,
        info: paper+", "+type+", "+X+corn,
        x: 1,
        price: selectedPrice,
    }])      
    }

    function handleAddToCart2 () {
      if (!selectedPriceBlock) {
        return;
      }

      props.setTotal(props.total + Number(selectedPrice));

      if(props.purchases.some((item)=>{return item.info === type && item.name === props.name})) {
        let a = props.purchases.find((item)=>{return item.info === type && item.name === props.name})
        a.x = a.x+1
        return;
      }
      
      props.setPurchases([...props.purchases, {
        name: props.name,
        info: type,
        x: 1,
        price: selectedPrice,
      }])      
    }

    React.useEffect(() => {
      setNavBlock(document.getElementsByClassName('designer__nav-btn_active')[0])
    }, []);

    const url = useLocation().pathname;

    const vizitki = [
      {
        th: 'Тип визиток',
        th2: 'Тип визиток',
        td1text: '1+0',
        td2text: '1+1',
        td3text: '4+0',
        td4text: '4+4',
      },
      {
        th: '50 шт',
        th2: '2000 шт',
        td1text: price,
        td2text: price*1.25,
        td3text: price*1.2,
        td4text: price*1.3,
        td1type: '1+0',
        td2type: '1+1',
        td3type: '4+0',
        td4type: '4+4',
      },
      {
        th: '100 шт',
        th2: '3000 шт',
        td1text: price*2,
        td2text: price*2*1.25,
        td3text: price*2*1.2,
        td4text: price*2*1.3,
        td1type: '1+0',
        td2type: '1+1',
        td3type: '4+0',
        td4type: '4+4',
      },
      {
        th: '200 шт',
        th2: '4000 шт',
        td1text: price*4,
        td2text: price*4*1.25,
        td3text: price*4*1.2,
        td4text: price*4*1.3,
        td1type: '1+0',
        td2type: '1+1',
        td3type: '4+0',
        td4type: '4+4',
      },
      {
        th: '300 шт',
        th2: '5000 шт',
        td1text: price*6,
        td2text: price*6*1.25,
        td3text: price*6*1.2,
        td4text: price*6*1.3,
        td1type: '1+0',
        td2type: '1+1',
        td3type: '4+0',
        td4type: '4+4',
      },
      {
        th: '400 шт',
        th2: '6000 шт',
        td1text: price*8,
        td2text: price*8*1.25,
        td3text: price*8*1.2,
        td4text: price*8*1.3,
        td1type: '1+0',
        td2type: '1+1',
        td3type: '4+0',
        td4type: '4+4',
      },
      {
        th: '500 шт',
        th2: '8000 шт',
        td1text: price*10,
        td2text: price*10*1.25,
        td3text: price*10*1.2,
        td4text: price*10*1.3,
        td1type: '1+0',
        td2type: '1+1',
        td3type: '4+0',
        td4type: '4+4',
      },
      {
        th: '1000 шт',
        th2: '10000 шт',
        td1text: price*20,
        td2text: price*20*1.25,
        td3text: price*20*1.2,
        td4text: price*20*1.3,
        td1type: '1+0',
        td2type: '1+1',
        td3type: '4+0',
        td4type: '4+4',
      },
      {
        th: '2000 шт',
        th2: '20000 шт',
        td1text: price*40,
        td2text: price*40*1.25,
        td3text: price*40*1.2,
        td4text: price*40*1.3,
        td1type: '1+0',
        td2type: '1+1',
        td3type: '4+0',
        td4type: '4+4',
      },
    ]

    const listovki = [
      {
        th: 'Формат (цвет)',
        th2: 'Формат (цвет)',
        td1text: 'А5 (4+0)',
        td2text: 'А5 (4+4)',
        td3text: 'А4 (4+0)',
        td4text: 'А4 (4+4)',
        td5text: 'А3 (4+0)',
        td6text: 'А3 (4+4)',
      },
      {
        th: '50 шт',
        th2: '1000 шт',
        td1text: price,
        td2text: price*1.6,
        td3text: price*1.5,
        td4text: price*2.5,
        td5text: price*2.4,
        td6text: price*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
      {
        th: '100 шт',
        th2: '2000 шт',
        td1text: price*2,
        td2text: price*2*1.6,
        td3text: price*2*1.5,
        td4text: price*2*2.5,
        td5text: price*2*2.4,
        td6text: price*2*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
      {
        th: '200 шт',
        th2: '3000 шт',
        td1text: price*4,
        td2text: price*4*1.6,
        td3text: price*4*1.5,
        td4text: price*4*2.5,
        td5text: price*4*2.4,
        td6text: price*4*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
      {
        th: '300 шт',
        th2: '4000 шт',
        td1text: price*6,
        td2text: price*6*1.6,
        td3text: price*6*1.5,
        td4text: price*6*2.5,
        td5text: price*6*2.4,
        td6text: price*6*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
      {
        th: '400 шт',
        th2: '5000 шт',
        td1text: price*8,
        td2text: price*8*1.6,
        td3text: price*8*1.5,
        td4text: price*8*2.5,
        td5text: price*8*2.4,
        td6text: price*8*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
      {
        th: '500 шт',
        th2: '6000 шт',
        td1text: price*10,
        td2text: price*10*1.6,
        td3text: price*10*1.5,
        td4text: price*10*2.5,
        td5text: price*10*2.4,
        td6text: price*10*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
      {
        th: '600 шт',
        th2: '7000 шт',
        td1text: price*12,
        td2text: price*12*1.6,
        td3text: price*12*1.5,
        td4text: price*12*2.5,
        td5text: price*12*2.4,
        td6text: price*12*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
      {
        th: '800 шт',
        th2: '8000 шт',
        td1text: price*16,
        td2text: price*16*1.6,
        td3text: price*16*1.5,
        td4text: price*16*2.5,
        td5text: price*16*2.4,
        td6text: price*16*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
      {
        th: '1000 шт',
        th2: '10000 шт',
        td1text: price*20,
        td2text: price*20*1.6,
        td3text: price*20*1.5,
        td4text: price*20*2.5,
        td5text: price*20*2.4,
        td6text: price*20*4,
        td1type: 'А5 (4+0)',
        td2type: 'А5 (4+4)',
        td3type: 'А4 (4+0)',
        td4type: 'А4 (4+4)',
        td5type: 'А3 (4+0)',
        td6type: 'А3 (4+4)',
      },
    ] // и объявления

    const bukleti = [
      {
        th: 'Формат (цвет)',
        th2: 'Формат (цвет)',
        td1text: 'А5 (1 сгиб)',
        td2text: 'А4 евро (2 сгиба)',
        td3text: 'А4 (1 сгиб)',
        td4text: 'А3 (1 сгиб)',
      },
      {
        th: '50 шт',
        th2: '1000 шт',
        td1text: price,
        td2text: price*1.6,
        td3text: price*1.5,
        td4text: price*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
      {
        th: '100 шт',
        th2: '2000 шт',
        td1text: price*2,
        td2text: price*2*1.6,
        td3text: price*2*1.5,
        td4text: price*2*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
      {
        th: '200 шт',
        th2: '3000 шт',
        td1text: price*4,
        td2text: price*4*1.6,
        td3text: price*4*1.5,
        td4text: price*4*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
      {
        th: '300 шт',
        th2: '4000 шт',
        td1text: price*6,
        td2text: price*6*1.6,
        td3text: price*6*1.5,
        td4text: price*6*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
      {
        th: '400 шт',
        th2: '5000 шт',
        td1text: price*8,
        td2text: price*8*1.6,
        td3text: price*8*1.5,
        td4text: price*8*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
      {
        th: '500 шт',
        th2: '6000 шт',
        td1text: price*10,
        td2text: price*10*1.6,
        td3text: price*10*1.5,
        td4text: price*10*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
      {
        th: '600 шт',
        th2: '7000 шт',
        td1text: price*12,
        td2text: price*12*1.6,
        td3text: price*12*1.5,
        td4text: price*12*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
      {
        th: '800 шт',
        th2: '8000 шт',
        td1text: price*16,
        td2text: price*16*1.6,
        td3text: price*16*1.5,
        td4text: price*16*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
      {
        th: '1000 шт',
        th2: '10000 шт',
        td1text: price*20,
        td2text: price*20*1.6,
        td3text: price*20*1.5,
        td4text: price*20*2.5,
        td1type: 'А5 (1 сгиб)',
        td2type: 'А4 евро (2 сгиба)',
        td3type: 'А4 (1 сгиб)',
        td4type: 'А3 (1 сгиб)',
      },
    ]

    const flaeri = [
      {
        th: 'Формат (цвет)',
        th2: 'Формат (цвет)',
        td1text: 'А7 (4+0)',
        td2text: 'А7 (4+4)',
        td3text: 'А6 (4+0)',
        td4text: 'А6 (4+4)',
        td5text: '98*210 (4+0)',
        td6text: '98*210 (4+4)',
      },
      {
        th: '50 шт',
        th2: '1000 шт',
        td1text: price,
        td2text: price*1.6,
        td3text: price*1.5,
        td4text: price*2.5,
        td5text: price*2.4,
        td6text: price*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
      {
        th: '100 шт',
        th2: '2000 шт',
        td1text: price*2,
        td2text: price*2*1.6,
        td3text: price*2*1.5,
        td4text: price*2*2.5,
        td5text: price*2*2.4,
        td6text: price*2*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
      {
        th: '200 шт',
        th2: '3000 шт',
        td1text: price*4,
        td2text: price*4*1.6,
        td3text: price*4*1.5,
        td4text: price*4*2.5,
        td5text: price*4*2.4,
        td6text: price*4*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
      {
        th: '300 шт',
        th2: '4000 шт',
        td1text: price*6,
        td2text: price*6*1.6,
        td3text: price*6*1.5,
        td4text: price*6*2.5,
        td5text: price*6*2.4,
        td6text: price*6*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
      {
        th: '400 шт',
        th2: '5000 шт',
        td1text: price*8,
        td2text: price*8*1.6,
        td3text: price*8*1.5,
        td4text: price*8*2.5,
        td5text: price*8*2.4,
        td6text: price*8*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
      {
        th: '500 шт',
        th2: '6000 шт',
        td1text: price*10,
        td2text: price*10*1.6,
        td3text: price*10*1.5,
        td4text: price*10*2.5,
        td5text: price*10*2.4,
        td6text: price*10*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
      {
        th: '600 шт',
        th2: '7000 шт',
        td1text: price*12,
        td2text: price*12*1.6,
        td3text: price*12*1.5,
        td4text: price*12*2.5,
        td5text: price*12*2.4,
        td6text: price*12*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
      {
        th: '800 шт',
        th2: '8000 шт',
        td1text: price*16,
        td2text: price*16*1.6,
        td3text: price*16*1.5,
        td4text: price*16*2.5,
        td5text: price*16*2.4,
        td6text: price*16*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
      {
        th: '1000 шт',
        th2: '10000 шт',
        td1text: price*20,
        td2text: price*20*1.6,
        td3text: price*20*1.5,
        td4text: price*20*2.5,
        td5text: price*20*2.4,
        td6text: price*20*4,
        td1type: 'А7 (4+0)',
        td2type: 'А7 (4+4)',
        td3type: 'А6 (4+0)',
        td4type: 'А6 (4+4)',
        td5type: '98*210 (4+0)',
        td6type: '98*210 (4+4)',
      },
    ]

    const otkritki = [
      {
        th: 'Вид открытки',
        th2: 'Вид приглашения',
        td1text: 'Карточка 150x100 мм (Классика)',
        td2text: 'Карточка 210x100 мм (Евро)',
        td3text: 'Со сложением 200x200мм в развороте',
        td4text: 'Со сложением 297x210 мм в развороте (Классика)',
        td5text: 'Со сложением 400x200мм в развороте',
      },
      {
        th: '30 шт',
        td1text: price,
        td2text: price*1.5,
        td3text: price*3,
        td4text: price*3,
        td5text: price*5,
        td1type: 'Карточка 150x100 мм (Классика)',
        td2type: 'Карточка 210x100 мм (Евро)',
        td3type: 'Со сложением 200x200мм в развороте',
        td4type: 'Со сложением 297x210 мм в развороте (Классика)',
        td5type: 'Со сложением 400x200мм в развороте',
      },
      {
        th: '50 шт',
        td1text: Math.round(price/3*5),
        td2text: price/3*5*1.5,
        td3text: price/3*5*3,
        td4text: price/3*5*3,
        td5text: Math.round(price/3*5*5),
        td1type: 'Карточка 150x100 мм (Классика)',
        td2type: 'Карточка 210x100 мм (Евро)',
        td3type: 'Со сложением 200x200мм в развороте',
        td4type: 'Со сложением 297x210 мм в развороте (Классика)',
        td5type: 'Со сложением 400x200мм в развороте',
      },
      {
        th: '100 шт',
        td1text: Math.round(price/3*10),
        td2text: price/3*10*1.5,
        td3text: price/3*10*3,
        td4text: price/3*10*3,
        td5text: Math.round(price/3*10*5),
        td1type: 'Карточка 150x100 мм (Классика)',
        td2type: 'Карточка 210x100 мм (Евро)',
        td3type: 'Со сложением 200x200мм в развороте',
        td4type: 'Со сложением 297x210 мм в развороте (Классика)',
        td5type: 'Со сложением 400x200мм в развороте',
      },
      {
        th: '150 шт',
        td1text: price*5,
        td2text: price*5*1.5,
        td3text: price*5*3,
        td4text: price*5*3,
        td5text: price*5*5,
        td1type: 'Карточка 150x100 мм (Классика)',
        td2type: 'Карточка 210x100 мм (Евро)',
        td3type: 'Со сложением 200x200мм в развороте',
        td4type: 'Со сложением 297x210 мм в развороте (Классика)',
        td5type: 'Со сложением 400x200мм в развороте',
      },
      {
        th: '200 шт',
        td1text: Math.round(price/3*20),
        td2text: price/3*20*1.5,
        td3text: price/3*20*3,
        td4text: price/3*20*3,
        td5text: Math.round(price/3*20*5),
        td1type: 'Карточка 150x100 мм (Классика)',
        td2type: 'Карточка 210x100 мм (Евро)',
        td3type: 'Со сложением 200x200мм в развороте',
        td4type: 'Со сложением 297x210 мм в развороте (Классика)',
        td5type: 'Со сложением 400x200мм в развороте',
      },
      {
        th: '300 шт',
        td1text: price*10,
        td2text: price*10*1.5,
        td3text: price*10*3,
        td4text: price*10*3,
        td5text: price*10*5,
        td1type: 'Карточка 150x100 мм (Классика)',
        td2type: 'Карточка 210x100 мм (Евро)',
        td3type: 'Со сложением 200x200мм в развороте',
        td4type: 'Со сложением 297x210 мм в развороте (Классика)',
        td5type: 'Со сложением 400x200мм в развороте',
      },
    ] // и приглашения

    const blanki = [
      {
        th: 'Формат и тип бумаги',
        td1text: 'А4 (4+0) офсетная 120 гр/м2',
        td2text: 'А4 (4+0) мелованная 130 гр/м2',
        td3text: 'А4 (4+0) Лен слоновая кость 120 гр/м2',
        td4text: 'А4 (4+0) Лен белый 120 гр/м2',
      },
      {
        th: '50 шт',
        td1text: price,
        td2text: price*1.1,
        td3text: price*1.8,
        td4text: price*1.8,
        td1type: 'А4 (4+0) офсетная 120 гр/м2',
        td2type: 'А4 (4+0) мелованная 130 гр/м2',
        td3type: 'А4 (4+0) Лен слоновая кость 120 гр/м2',
        td4type: 'А4 (4+0) Лен белый 120 гр/м2',
      },
      {
        th: '100 шт',
        td1text: price*2,
        td2text: price*2*1.1,
        td3text: price*2*1.8,
        td4text: price*2*1.8,
        td1type: 'А4 (4+0) офсетная 120 гр/м2',
        td2type: 'А4 (4+0) мелованная 130 гр/м2',
        td3type: 'А4 (4+0) Лен слоновая кость 120 гр/м2',
        td4type: 'А4 (4+0) Лен белый 120 гр/м2',
      },
      {
        th: '200 шт',
        td1text: price*4,
        td2text: price*4*1.1,
        td3text: price*4*1.8,
        td4text: price*4*1.8,
        td1type: 'А4 (4+0) офсетная 120 гр/м2',
        td2type: 'А4 (4+0) мелованная 130 гр/м2',
        td3type: 'А4 (4+0) Лен слоновая кость 120 гр/м2',
        td4type: 'А4 (4+0) Лен белый 120 гр/м2',
      },
      {
        th: '300 шт',
        td1text: price*6,
        td2text: Math.round(price*6*1.1),
        td3text: price*6*1.8,
        td4text: price*6*1.8,
        td1type: 'А4 (4+0) офсетная 120 гр/м2',
        td2type: 'А4 (4+0) мелованная 130 гр/м2',
        td3type: 'А4 (4+0) Лен слоновая кость 120 гр/м2',
        td4type: 'А4 (4+0) Лен белый 120 гр/м2',
      },
      {
        th: '400 шт',
        td1text: price*8,
        td2text: price*8*1.1,
        td3text: price*8*1.8,
        td4text: price*8*1.8,
        td1type: 'А4 (4+0) офсетная 120 гр/м2',
        td2type: 'А4 (4+0) мелованная 130 гр/м2',
        td3type: 'А4 (4+0) Лен слоновая кость 120 гр/м2',
        td4type: 'А4 (4+0) Лен белый 120 гр/м2',
      },
      {
        th: '500 шт',
        td1text: price*10,
        td2text: price*10*1.1,
        td3text: price*10*1.8,
        td4text: price*10*1.8,
        td1type: 'А4 (4+0) офсетная 120 гр/м2',
        td2type: 'А4 (4+0) мелованная 130 гр/м2',
        td3type: 'А4 (4+0) Лен слоновая кость 120 гр/м2',
        td4type: 'А4 (4+0) Лен белый 120 гр/м2',
      },
    ]

    const konverti = [
      {
        th: 'Формат',
        td1text: 'C6',
        td2text: 'E65 (евро)',
        td3text: 'С5',
        td4text: 'С4',
      },
      {
        th: '50 шт',
        td1text: price,
        td2text: price*1.1,
        td3text: price*1.2,
        td4text: price*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
      {
        th: '100 шт',
        td1text: price*2,
        td2text: price*2*1.1,
        td3text: price*2*1.2,
        td4text: price*2*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
      {
        th: '200 шт',
        td1text: price*4,
        td2text: price*4*1.1,
        td3text: price*4*1.2,
        td4text: price*4*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
      {
        th: '300 шт',
        td1text: price*6,
        td2text: Math.round(price*6*1.1),
        td3text: price*6*1.2,
        td4text: price*6*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
      {
        th: '400 шт',
        td1text: price*8,
        td2text: price*8*1.1,
        td3text: price*8*1.2,
        td4text: price*8*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
      {
        th: '500 шт',
        td1text: price*10,
        td2text: price*10*1.1,
        td3text: price*10*1.2,
        td4text: price*10*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
      {
        th: '600 шт',
        td1text: price*12,
        td2text: Math.round(price*12*1.1),
        td3text: price*12*1.2,
        td4text: price*12*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
      {
        th: '800 шт',
        td1text: price*16,
        td2text: price*16*1.1,
        td3text: price*16*1.2,
        td4text: price*16*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
      {
        th: '1000 шт',
        td1text: price*20,
        td2text: price*20*1.1,
        td3text: price*20*1.2,
        td4text: price*20*1.5,
        td1type: 'C6',
        td2type: 'E65 (евро)',
        td3type: 'С5',
        td4type: 'С4',
      },
    ]

    return (
      <>
      {url === "/vizitki"
      ?
      <div className="designer">
        <h1 className="desinger__title">Печать визиток</h1>
        <div className="designer__tabs">
          <div className="designer__nav">
            <button className="designer__nav-btn designer__nav-btn_active" value='1' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={print} alt='img'></img>
              <p className="designer__nav-btn-name" >Цифровая печать</p>
            </button>
            <button className="designer__nav-btn" value='2' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={paper} alt='img'></img>
              <p className="designer__nav-btn-name">
                Офсетная печать
              </p>
            </button>
            <button className="designer__nav-btn" value='3' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={pen} alt='img'></img>
              <p className="designer__nav-btn-name">Дизайн</p>
            </button>
          </div>
          {navBlockValue === 1
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати цифровым способом 1-2 дня
                </h2>
                <div className="designer__tab-control">
                  <select className='designer__tab-control-select' onChange={handleChangeSelect}>
                    <option value="0">Мелованная бумага без отделки</option>
                    <option value="1">Мелованная бумага ламинация матовая (75 микрона)</option>
                    <option value="2">Дизайнерская бумага "Лён"</option>
                  </select>
                  <label>
                    <input type="checkbox" onChange={(e)=>{handleChangeСorner(e)}}></input>
                    Скругление углов
                  </label>
                </div>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                      <thead>
                        <tr>
                          {vizitki.map((column)=>{
                            return (
                              <th className='designer__tab-table-th'>{column.th}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {vizitki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td'x={column.th} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {vizitki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td'x={column.th} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {vizitki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td'x={column.th} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {vizitki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td'x={column.th} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              : navBlockValue === 2 
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати офсетным способом 3-4 дня
                </h2>
                <div className="designer__tab-control">
                  <select className='designer__tab-control-select' onChange={handleChangeSelect}>
                    <option value="0">Мелованный картон без отделки</option>
                    <option value="1">Мелованный картон глянцевая ламинация</option>
                  </select>
                  <label>
                    <input type="checkbox" onChange={(e)=>{handleChangeСorner(e)}}></input>
                    Скругление углов
                  </label>
                </div>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                    <thead>
                        <tr>
                          {vizitki.map((column)=>{
                            return (
                              <th className='designer__tab-table-th'>{column.th2}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {vizitki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th2} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {vizitki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th2} types={column.td4type} value={column.t42text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              :
              <div className='designer__design'>
                <table className="designer__design-table">
                  <thead>
                    <tr>
                      <th className='designer__design-table-th'>Услуга</th>
                      <th className='designer__design-table-th'>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='designer__design-table-td'>Дизайн-макет визитки по тех. заданию</td>
                      <td className='designer__design-table-td' x={''} types='Дизайн-макет визитки по тех. заданию' value='900' onClick={(e)=>{handleSelectPrice(e)}}>900 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Дизайн-макет визитки</td>
                      <td className='designer__design-table-td' x={''} types='Дизайн-макет визитки' value='1690' onClick={(e)=>{handleSelectPrice(e)}}>1690 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Отрисовка логотипа по фото</td>
                      <td className='designer__design-table-td' x={''} types='Отрисовка логотипа по фото' value='500' onClick={(e)=>{handleSelectPrice(e)}}>500 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Правки в макет</td>
                      <td className='designer__design-table-td' x={''} types='Правки в макет' value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Подготовка макета к печати</td>
                      <td className='designer__design-table-td' x={''} types='Подготовка макета к печати' value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                  </tbody>
                  <button className="designer__design-btn" onClick={()=>{handleAddToCart2()}}>Добавить в корзину</button>
                </table>
                  <div className='designer__design-info'>
                    <h2 className='designer__design-info-title'>Вы можете подготовить макет самостоятельно по нашим требованиям</h2>
                    <p className='designer__design-info-text'>Мы принимаем макеты в следующих форматах:</p>
                    <div className='designer__design-icons'>
                      <img src={pdf} className='designer__design-icon' alt='icon'></img>
                      <img src={ai} className='designer__design-icon' alt='icon'></img>
                      <img src={eps} className='designer__design-icon' alt='icon'></img>
                      <img src={cdr} className='designer__design-icon' alt='icon'></img>
                      <img src={tiff} className='designer__design-icon' alt='icon'></img>
                      <img src={jpg} className='designer__design-icon' alt='icon'></img>
                      <img src={png} className='designer__design-icon' alt='icon'></img>
                    </div>
                    <p className='designer__design-info-text'>Ваш Файл должен соответвовать нашим
                      требованиям к макету
                    </p>
                  </div>
              </div>
              }
          </div>
      </div>
      : url === "/pechat-listovok" || url === '/pechat-obyavleniy'
      ?
      <div className="designer">
        <h1 className="desinger__title">{url === "/pechat-listovok" ? 'Печать листовок' : 'Печать объявлений'}</h1>
        <div className="designer__tabs">
          <div className="designer__nav">
            <button className="designer__nav-btn designer__nav-btn_active" value='1' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={print} alt='img'></img>
              <p className="designer__nav-btn-name" >Цифровая печать</p>
            </button>
            <button className="designer__nav-btn" value='2' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={paper} alt='img'></img>
              <p className="designer__nav-btn-name">
                Офсетная печать
              </p>
            </button>
            <button className="designer__nav-btn" value='3' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={pen} alt='img'></img>
              <p className="designer__nav-btn-name">Дизайн</p>
            </button>
          </div>
          {navBlockValue === 1
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати цифровым способом 1-2 дня
                </h2>
                <div className="designer__tab-control">
                  <select className='designer__tab-control-select' onChange={handleChangeSelect}>
                    <option value="0">Мелованная бумага без отделки</option>
                    <option value="1">Мелованная бумага ламинация матовая (75 микрона)</option>
                    <option value="2">Дизайнерская бумага "Лён"</option>
                  </select>
                </div>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                    <thead>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <th className='designer__tab-table-th'>{column.th}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th} types={column.td5type} value={column.td5text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td5text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td6type} value={column.td6text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td6text}</td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              : navBlockValue === 2 
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати офсетным способом 3-4 дня
                </h2>
                <div className="designer__tab-control">
                  <select className='designer__tab-control-select' onChange={handleChangeSelect}>
                    <option value="0">Мелованный картон без отделки</option>
                    <option value="1">Мелованный картон глянцевая ламинация</option>
                  </select>
                </div>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                  <thead>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <th className='designer__tab-table-th'>{column.th2}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th2} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th2} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th2} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th2} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th2} types={column.td5type} value={column.td5text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td5text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <td className='designer__tab-table-td'x={column.th2} types={column.td6type} value={column.td6text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td6text}</td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              :
              <div className='designer__design'>
                <table className="designer__design-table">
                  <thead>
                    <tr>
                      <th className='designer__design-table-th'>Услуга</th>
                      <th className='designer__design-table-th'>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='designer__design-table-td'>{url === "/pechat-listovok" ? 'Дизайн-макет листовки с одной стороны' : 'Дизайн-макет объявления с одной стороны'}</td>
                      <td className='designer__design-table-td'  types={url === "/pechat-listovok" ? 'Дизайн-макет листовки с одной стороны' : 'Дизайн-макет объявления с одной стороны'} value='900' onClick={(e)=>{handleSelectPrice(e)}}>900 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>{url === "/pechat-listovok" ? 'Дизайн-макет листовки с двух сторон' : 'Дизайн-макет объявления с двух сторон'}</td>
                      <td className='designer__design-table-td' types={url === "/pechat-listovok" ? 'Дизайн-макет листовки с двух сторон' : 'Дизайн-макет объявления с двух сторон'} value='1200' onClick={(e)=>{handleSelectPrice(e)}}>1200 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Отрисовка логотипа по фото</td>
                      <td className='designer__design-table-td' types='Отрисовка логотипа по фото' value='500' onClick={(e)=>{handleSelectPrice(e)}}>500 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Правки в макет</td>
                      <td className='designer__design-table-td' types='Правки в макет' value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Подготовка макета к печати</td>
                      <td className='designer__design-table-td' types='Подготовка макета к печати' value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                  </tbody>
                  <button className="designer__design-btn" onClick={()=>{handleAddToCart2()}}>Добавить в корзину</button>
                </table>
                  <div className='designer__design-info'>
                    <h2 className='designer__design-info-title'>Вы можете подготовить макет самостоятельно по нашим требованиям</h2>
                    <p className='designer__design-info-text'>Мы принимаем макеты в следующих форматах:</p>
                    <div className='designer__design-icons'>
                      <img src={pdf} className='designer__design-icon' alt='icon'></img>
                      <img src={ai} className='designer__design-icon' alt='icon'></img>
                      <img src={eps} className='designer__design-icon' alt='icon'></img>
                      <img src={cdr} className='designer__design-icon' alt='icon'></img>
                      <img src={tiff} className='designer__design-icon' alt='icon'></img>
                      <img src={jpg} className='designer__design-icon' alt='icon'></img>
                      <img src={png} className='designer__design-icon' alt='icon'></img>
                    </div>
                    <p className='designer__design-info-text'>Ваш Файл должен соответвовать нашим
                      требованиям к макету
                    </p>
                  </div>
              </div>
              }
          </div>
      </div>
      : url === "/pechat-bukletov"
      ?
      <div className="designer">
        <h1 className="desinger__title">Печать рекламных буклетов</h1>
        <div className="designer__tabs">
          <div className="designer__nav">
            <button className="designer__nav-btn designer__nav-btn_active" value='1' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={print} alt='img'></img>
              <p className="designer__nav-btn-name" >Цифровая печать</p>
            </button>
            <button className="designer__nav-btn" value='2' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={paper} alt='img'></img>
              <p className="designer__nav-btn-name">
                Офсетная печать
              </p>
            </button>
            <button className="designer__nav-btn" value='3' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={pen} alt='img'></img>
              <p className="designer__nav-btn-name">Дизайн</p>
            </button>
          </div>
          {navBlockValue === 1
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати цифровым способом 1-2 дня
                </h2>
                <div className="designer__tab-control">
                  <select className='designer__tab-control-select' onChange={handleChangeSelect}>
                    <option value="0">Мелованная бумага без отделки</option>
                    <option value="1">Мелованная бумага ламинация матовая (75 микрона)</option>
                    <option value="2">Дизайнерская бумага "Лён"</option>
                  </select>
                </div>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                    <thead>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <th className='designer__tab-table-th'>{column.th}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              : navBlockValue === 2 
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати офсетным способом 3-4 дня
                </h2>
                <div className="designer__tab-control">
                  <select className='designer__tab-control-select' onChange={handleChangeSelect}>
                    <option value="0">Мелованный картон без отделки</option>
                    <option value="1">Мелованный картон глянцевая ламинация</option>
                  </select>
                </div>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                  <thead>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <th className='designer__tab-table-th'>{column.th2}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {bukleti.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              :
              <div className='designer__design'>
                <table className="designer__design-table">
                  <thead>
                    <tr>
                      <th className='designer__design-table-th'>Услуга</th>
                      <th className='designer__design-table-th'>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='designer__design-table-td'>Дизайн-макет буклета (1 сгиб, 4 полосы)</td>
                      <td className='designer__design-table-td' types='Дизайн-макет буклета (1 сгиб, 4 полосы)' value='1400' onClick={(e)=>{handleSelectPrice(e)}}>1400 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Дизайн-макет буклета (2 сгиба, 6 полос)</td>
                      <td className='designer__design-table-td' types='Дизайн-макет буклета (2 сгиба, 6 полос)' value='2200' onClick={(e)=>{handleSelectPrice(e)}}>2200 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Отрисовка логотипа по фото</td>
                      <td className='designer__design-table-td' types='Отрисовка логотипа по фото' value='500' onClick={(e)=>{handleSelectPrice(e)}}>500 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Правки в макет</td>
                      <td className='designer__design-table-td' types='Правки в макет' value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Подготовка макета к печати</td>
                      <td className='designer__design-table-td' types='Подготовка макета к печати' value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                  </tbody>
                  <button className="designer__design-btn" onClick={()=>{handleAddToCart2()}}>Добавить в корзину</button>
                </table>
                  <div className='designer__design-info'>
                    <h2 className='designer__design-info-title'>Вы можете подготовить макет самостоятельно по нашим требованиям</h2>
                    <p className='designer__design-info-text'>Мы принимаем макеты в следующих форматах:</p>
                    <div className='designer__design-icons'>
                      <img src={pdf} className='designer__design-icon' alt='icon'></img>
                      <img src={ai} className='designer__design-icon' alt='icon'></img>
                      <img src={eps} className='designer__design-icon' alt='icon'></img>
                      <img src={cdr} className='designer__design-icon' alt='icon'></img>
                      <img src={tiff} className='designer__design-icon' alt='icon'></img>
                      <img src={jpg} className='designer__design-icon' alt='icon'></img>
                      <img src={png} className='designer__design-icon' alt='icon'></img>
                    </div>
                    <p className='designer__design-info-text'>Ваш Файл должен соответвовать нашим
                      требованиям к макету
                    </p>
                  </div>
              </div>
              }
          </div>
      </div>
      : url === "/pechat-flaerov"
      ?
      <div className="designer">
        <h1 className="desinger__title">Печать флаеров</h1>
        <div className="designer__tabs">
          <div className="designer__nav">
            <button className="designer__nav-btn designer__nav-btn_active" value='1' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={print} alt='img'></img>
              <p className="designer__nav-btn-name" >Цифровая печать</p>
            </button>
            <button className="designer__nav-btn" value='2' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={paper} alt='img'></img>
              <p className="designer__nav-btn-name">
                Офсетная печать
              </p>
            </button>
            <button className="designer__nav-btn" value='3' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={pen} alt='img'></img>
              <p className="designer__nav-btn-name">Дизайн</p>
            </button>
          </div>
          {navBlockValue === 1
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати цифровым способом 1-2 дня
                </h2>
                <div className="designer__tab-control">
                  <select className='designer__tab-control-select' onChange={handleChangeSelect}>
                    <option value="0">Мелованная бумага без отделки</option>
                    <option value="1">Мелованная бумага ламинация матовая (75 микрона)</option>
                    <option value="2">Дизайнерская бумага "Лён"</option>
                  </select>
                </div>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                    <thead>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <th className='designer__tab-table-th'>{column.th}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td5type} value={column.td5text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td5text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th} types={column.td6type} value={column.td6text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td6text}</td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              : navBlockValue === 2 
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати офсетным способом 3-4 дня
                </h2>
                <div className="designer__tab-control">
                  <select className='designer__tab-control-select' onChange={handleChangeSelect}>
                    <option value="0">Мелованный картон без отделки</option>
                    <option value="1">Мелованный картон глянцевая ламинация</option>
                  </select>
                </div>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                  <thead>
                      <tr>
                        {listovki.map((column)=>{
                          return (
                            <th className='designer__tab-table-th'>{column.th2}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td5type} value={column.td5text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td5text}</td>
                          )
                        })}
                      </tr>
                      <tr>
                        {flaeri.map((column)=>{
                          return (
                            <td className='designer__tab-table-td' x={column.th2} types={column.td6type} value={column.td6text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td6text}</td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              :
              <div className='designer__design'>
                <table className="designer__design-table">
                  <thead>
                    <tr>
                      <th className='designer__design-table-th'>Услуга</th>
                      <th className='designer__design-table-th'>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='designer__design-table-td'>Дизайн-макет флаера с одной стороны</td>
                      <td className='designer__design-table-td' types="Дизайн-макет флаера с одной стороны" value='900' onClick={(e)=>{handleSelectPrice(e)}}>900 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Дизайн-макет флаера с двух сторон</td>
                      <td className='designer__design-table-td' types="Дизайн-макет флаера с двух сторон" value='1200' onClick={(e)=>{handleSelectPrice(e)}}>1200 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Отрисовка логотипа по фото</td>
                      <td className='designer__design-table-td' types="Отрисовка логотипа по фото" value='500' onClick={(e)=>{handleSelectPrice(e)}}>500 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Правки в макет</td>
                      <td className='designer__design-table-td' types="Правки в макет" value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Подготовка макета к печати</td>
                      <td className='designer__design-table-td' types="Подготовка макета к печати" value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                  </tbody>
                  <button className="designer__design-btn" onClick={()=>{handleAddToCart2()}}>Добавить в корзину</button>
                </table>
                  <div className='designer__design-info'>
                    <h2 className='designer__design-info-title'>Вы можете подготовить макет самостоятельно по нашим требованиям</h2>
                    <p className='designer__design-info-text'>Мы принимаем макеты в следующих форматах:</p>
                    <div className='designer__design-icons'>
                      <img src={pdf} className='designer__design-icon' alt='icon'></img>
                      <img src={ai} className='designer__design-icon' alt='icon'></img>
                      <img src={eps} className='designer__design-icon' alt='icon'></img>
                      <img src={cdr} className='designer__design-icon' alt='icon'></img>
                      <img src={tiff} className='designer__design-icon' alt='icon'></img>
                      <img src={jpg} className='designer__design-icon' alt='icon'></img>
                      <img src={png} className='designer__design-icon' alt='icon'></img>
                    </div>
                    <p className='designer__design-info-text'>Ваш Файл должен соответвовать нашим
                      требованиям к макету
                    </p>
                  </div>
              </div>
              }
          </div>
      </div>
      : url === "/pechat-otkritok" || url === "/priglasitelnye"
      ?
      <div className="designer">
        <h1 className="desinger__title">{url === "/pechat-otkritok" ? 'Печать открыток' : 'Пригласительные'}</h1>
        <div className="designer__tabs">
          <div className="designer__nav">
            <button className="designer__nav-btn designer__nav-btn_active" value='1' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={print} alt='img'></img>
              <p className="designer__nav-btn-name" >Цифровая печать</p>
            </button>
            <button className="designer__nav-btn" value='3' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={pen} alt='img'></img>
              <p className="designer__nav-btn-name">Дизайн</p>
            </button>
          </div>
          {navBlockValue === 1
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати цифровым способом 1-2 дня
                </h2>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                      <thead>
                        <tr>
                          {otkritki.map((column)=>{
                            return (
                              <th className='designer__tab-table-th'>{column.th}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {otkritki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {otkritki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {otkritki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {otkritki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {otkritki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td5type} value={column.td5text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td5text}</td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              :
              <div className='designer__design'>
                <table className="designer__design-table">
                  <thead>
                    <tr>
                      <th className='designer__design-table-th'>Услуга</th>
                      <th className='designer__design-table-th'>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='designer__design-table-td'>{url === "/pechat-otkritok" ? 'Дизайн-макет открытки' : 'Дизайн-макет приглашения'}</td>
                      <td className='designer__design-table-td' types={url === "/pechat-otkritok" ? 'Дизайн-макет открытки' : 'Дизайн-макет приглашения'} value='900' onClick={(e)=>{handleSelectPrice(e)}}>900 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Отрисовка логотипа по фото</td>
                      <td className='designer__design-table-td' types="Отрисовка логотипа по фото" value='500' onClick={(e)=>{handleSelectPrice(e)}}>500 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Правки в макет</td>
                      <td className='designer__design-table-td' types="Правки в макет" value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Подготовка макета к печати</td>
                      <td className='designer__design-table-td' types="Подготовка макета к печати" value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                  </tbody>
                  <button className="designer__design-btn" onClick={()=>{handleAddToCart2()}}>Добавить в корзину</button>
                </table>
                  <div className='designer__design-info'>
                    <h2 className='designer__design-info-title'>Вы можете подготовить макет самостоятельно по нашим требованиям</h2>
                    <p className='designer__design-info-text'>Мы принимаем макеты в следующих форматах:</p>
                    <div className='designer__design-icons'>
                      <img src={pdf} className='designer__design-icon' alt='icon'></img>
                      <img src={ai} className='designer__design-icon' alt='icon'></img>
                      <img src={eps} className='designer__design-icon' alt='icon'></img>
                      <img src={cdr} className='designer__design-icon' alt='icon'></img>
                      <img src={tiff} className='designer__design-icon' alt='icon'></img>
                      <img src={jpg} className='designer__design-icon' alt='icon'></img>
                      <img src={png} className='designer__design-icon' alt='icon'></img>
                    </div>
                    <p className='designer__design-info-text'>Ваш Файл должен соответвовать нашим
                      требованиям к макету
                    </p>
                  </div>
              </div>
              }
          </div>
      </div>
      : url === "/pechat-blankov"
      ?
      <div className="designer">
        <h1 className="desinger__title">Печать бланков</h1>
        <div className="designer__tabs">
          <div className="designer__nav">
            <button className="designer__nav-btn designer__nav-btn_active" value='1' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={print} alt='img'></img>
              <p className="designer__nav-btn-name" >Цифровая печать</p>
            </button>
            <button className="designer__nav-btn" value='3' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={pen} alt='img'></img>
              <p className="designer__nav-btn-name">Дизайн</p>
            </button>
          </div>
          {navBlockValue === 1
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати цифровым способом 1-2 дня
                </h2>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                      <thead>
                        <tr>
                          {blanki.map((column)=>{
                            return (
                              <th className='designer__tab-table-th'>{column.th}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {blanki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {blanki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {blanki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {blanki.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              :
              <div className='designer__design'>
                <table className="designer__design-table">
                  <thead>
                    <tr>
                      <th className='designer__design-table-th'>Услуга</th>
                      <th className='designer__design-table-th'>Цена</th>
                    </tr>
                  </thead>
                  <tbody> 
                    <tr>
                      <td className='designer__design-table-td'>Дизайн-макет фирменного бланка</td>
                      <td className='designer__design-table-td' types="Дизайн-макет фирменного бланка" value='900' onClick={(e)=>{handleSelectPrice(e)}}>900 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Отрисовка логотипа по фото</td>
                      <td className='designer__design-table-td' types="Отрисовка логотипа по фото" value='500' onClick={(e)=>{handleSelectPrice(e)}}>500 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Правки в макет</td>
                      <td className='designer__design-table-td' types="Правки в макет" alue='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Подготовка макета к печати</td>
                      <td className='designer__design-table-td' types="Подготовка макета к печати" value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                  </tbody>
                  <button className="designer__design-btn" onClick={()=>{handleAddToCart2()}}>Добавить в корзину</button>
                </table>
                  <div className='designer__design-info'>
                    <h2 className='designer__design-info-title'>Вы можете подготовить макет самостоятельно по нашим требованиям</h2>
                    <p className='designer__design-info-text'>Мы принимаем макеты в следующих форматах:</p>
                    <div className='designer__design-icons'>
                      <img src={pdf} className='designer__design-icon' alt='icon'></img>
                      <img src={ai} className='designer__design-icon' alt='icon'></img>
                      <img src={eps} className='designer__design-icon' alt='icon'></img>
                      <img src={cdr} className='designer__design-icon' alt='icon'></img>
                      <img src={tiff} className='designer__design-icon' alt='icon'></img>
                      <img src={jpg} className='designer__design-icon' alt='icon'></img>
                      <img src={png} className='designer__design-icon' alt='icon'></img>
                    </div>
                    <p className='designer__design-info-text'>Ваш Файл должен соответвовать нашим
                      требованиям к макету
                    </p>
                  </div>
              </div>
              }
          </div>
      </div>
      : url === "/pechat-konvertov"
      ?
      <div className="designer">
        <h1 className="desinger__title">Печать конвертов</h1>
        <div className="designer__tabs">
          <div className="designer__nav">
            <button className="designer__nav-btn designer__nav-btn_active" value='1' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={print} alt='img'></img>
              <p className="designer__nav-btn-name" >Цифровая печать</p>
            </button>
            <button className="designer__nav-btn" value='3' onClick={(e)=>{handleChangeNavBlock(e)}}>
              <img className="designer__nav-btn-img" src={pen} alt='img'></img>
              <p className="designer__nav-btn-name">Дизайн</p>
            </button>
          </div>
          {navBlockValue === 1
              ?
              <div className="designer__tab">  
                <h2 className="designer__tab-term">
                  Срок печати цифровым способом 1-2 дня
                </h2>
                <div className='designer__tab-table-container'>
                  <table className="designer__tab-table">
                      <thead>
                        <tr>
                          {konverti.map((column)=>{
                            return (
                              <th className='designer__tab-table-th'>{column.th}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {konverti.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td1type} value={column.td1text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td1text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {konverti.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td2type} value={column.td2text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td2text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {konverti.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td3type} value={column.td3text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td3text}</td>
                            )
                          })}
                        </tr>
                        <tr>
                          {konverti.map((column)=>{
                            return (
                              <td className='designer__tab-table-td' x={column.th} types={column.td4type} value={column.td4text} onClick={(e)=>{handleSelectPrice(e)}}>{column.td4text}</td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                </div>
                <button className="designer__tab-btn" onClick={()=>{handleAddToCart()}}>Добавить в корзину</button>
              </div>
              :
              <div className='designer__design'>
                <table className="designer__design-table">
                  <thead>
                    <tr>
                      <th className='designer__design-table-th'>Услуга</th>
                      <th className='designer__design-table-th'>Цена</th>
                    </tr>
                  </thead>
                  <tbody> 
                    <tr>
                      <td className='designer__design-table-td'>Дизайн-макет конверта</td>
                      <td className='designer__design-table-td' types="Дизайн-макет конверта" value='900' onClick={(e)=>{handleSelectPrice(e)}}>900 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Отрисовка логотипа по фото</td>
                      <td className='designer__design-table-td' types="Отрисовка логотипа по фото" value='500' onClick={(e)=>{handleSelectPrice(e)}}>500 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Правки в макет</td>
                      <td className='designer__design-table-td' types="Правки в макет" value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                    <tr>
                      <td className='designer__design-table-td'>Подготовка макета к печати</td>
                      <td className='designer__design-table-td' types="Подготовка макета к печати" value='100' onClick={(e)=>{handleSelectPrice(e)}}>100 руб</td>
                    </tr>
                  </tbody>
                  <button className="designer__design-btn" onClick={()=>{props.setTotal(props.total + Number(selectedPrice));}}>Добавить в корзину</button>
                </table>
                  <div className='designer__design-info'>
                    <h2 className='designer__design-info-title'>Вы можете подготовить макет самостоятельно по нашим требованиям</h2>
                    <p className='designer__design-info-text'>Мы принимаем макеты в следующих форматах:</p>
                    <div className='designer__design-icons'>
                      <img src={pdf} className='designer__design-icon' alt='icon'></img>
                      <img src={ai} className='designer__design-icon' alt='icon'></img>
                      <img src={eps} className='designer__design-icon' alt='icon'></img>
                      <img src={cdr} className='designer__design-icon' alt='icon'></img>
                      <img src={tiff} className='designer__design-icon' alt='icon'></img>
                      <img src={jpg} className='designer__design-icon' alt='icon'></img>
                      <img src={png} className='designer__design-icon' alt='icon'></img>
                    </div>
                    <p className='designer__design-info-text'>Ваш Файл должен соответвовать нашим
                      требованиям к макету
                    </p>
                  </div>
              </div>
              }
          </div>
      </div>
      :
      <h1>NO</h1>
      }
      </>
    );
  }
  
  export default Designer;