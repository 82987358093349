import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from "../Header/Header";
import Footer from '../Footer/Footer';
import PrintingHouse from '../PrintingHouse/PrintingHouse';
import FirstCalculator from '../FirstCalculator/FirstCalculator'
import SecondCalculator from '../SecondCalculator/SecondCalculator';
import Basket from '../Basket/Basket';
import React from 'react';

function App() {

  const [total, setTotal] = React.useState(0);
  const [purchases, setPurchases] = React.useState([]);

  return (
    <div className="App">
      <Header total={total}/>
      <Routes>
          <Route path="/" element={<PrintingHouse />} />
          <Route path="/vizitki" element={<FirstCalculator name='Визитки' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-nakleek" element={<SecondCalculator data='Круглые наклейки с логотипом' name='Наклейки' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-listovok" element={<FirstCalculator name='Листовки' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-bukletov" element={<FirstCalculator name='Буклеты' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-flaerov" element={<FirstCalculator name='Флаеры' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-katalogov" element={<SecondCalculator data='Каталог на скобу' name='Каталоги' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-obyavleniy" element={<FirstCalculator name='Объявления' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-otkritok" element={<FirstCalculator name='Открытки' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-plakatov" element={<SecondCalculator data='Плакаты' name='Плакаты' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-blankov" element={<FirstCalculator name='Бланки' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-kalendarey" element={<SecondCalculator data='Квартальные календари на 3-х пружинах' name='Календари' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-prezentaciy" element={<SecondCalculator data='Презентация' name='Презентации' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-bannerov" element={<SecondCalculator data='Баннеры' name='Баннеры' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-chertezhej" element={<SecondCalculator data='Чертежи' name='Чертежи' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-konvertov" element={<FirstCalculator name='Конверты' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/bloknoty" element={<SecondCalculator data='Блокнот' name='Блокноты' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/pechat-etiketok" element={<SecondCalculator data='Круглые этикетки с логотипом' name='Этикетки' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
          <Route path="/priglasitelnye" element={<FirstCalculator name='Приглашения' total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}/>} />
      </Routes>
      <Basket total={total} setTotal={setTotal} purchases={purchases} setPurchases={setPurchases}></Basket>
      <Footer />
    </div>
  );
}

export default App;
