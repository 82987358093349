import React from 'react';

function StickerForm(props) {

    const [unitPrice, setUnitPrice] = React.useState(5);
    const [dia, setDia] = React.useState(5);
    const [x, setX] = React.useState(1);
    const [kinds, setKinds] = React.useState(1);
    const [print, setPrint] = React.useState(1);
    const [material, setMaterial] = React.useState(1);
    const [lamination, setLamination] = React.useState(1);
    const [slicing, setSlicing] = React.useState(1);

    function handleChangeDia(e) {
        setDia(e.target.value);
        setUnitPrice(e.target.value*unitPrice/dia)
      }

    function handleChangeX(e) {
        setX(e.target.value);
    }

    function handleChangeKinds(e) {
        setKinds(e.target.value);
        setUnitPrice(e.target.value*unitPrice/kinds)
    }

    function handleChangePrint(e) {
        setPrint(e.target.value);
        setUnitPrice(e.target.value*unitPrice/print)
    }

    function handleChangeMaterial(e) {
        setMaterial(e.target.value);
        setUnitPrice(e.target.value*unitPrice/material)
    }

    function handleChangeLamination(e) {
        setLamination(e.target.value);
        setUnitPrice(e.target.value*unitPrice/lamination)
    }

    function handleChangeSlicing(e) {
        setSlicing(e.target.value);
        setUnitPrice(e.target.value*unitPrice/slicing)
    }

    function handleSubmit(e) {
        e.preventDefault()
        props.setTotal(props.total + Math.round(unitPrice)*x)

        let pri = ''
        let mat = ''
        let lam = ''
        let sli = ''

        if (Number(print) === 1) {
            pri = 'Струйная экосольвентная'
          } else if (Number(print) === 1.1) {
            pri = 'Лазерная печать'
          } else {
            pri = 'УФ печать (белым цветом)'
          }

          if (Number(material) === 1) {
            mat = 'белая матовая пленка'
          } else if (Number(material) === 1.1) {
            mat = 'белая глянцевая пленка'
          } else {
            mat = 'прозрачная матовая пленка'
          }

          if (Number(lamination) === 1) {
            lam = 'без ламинации'
          } else if (Number(lamination) === 1.1) {
            lam = 'Матовая (25 мкр)'
          } else {
            lam = 'Глянцевая (25 мкр)'
          }

          if (Number(slicing) === 1) {
            sli = 'на общей подложке 300х800 мм'
          } else if (Number(slicing) === 1.1) {
            sli = 'на общей подложке 330х487 мм'
          } else {
            sli = 'ручная нарезка'
          }
          
          if(props.purchases.some((item)=>{return item.info === dia+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+sli+props.data && item.name === props.name})) {
            let a = props.purchases.find((item)=>{return item.info === dia+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+sli+props.data && item.name === props.name})
            a.x = a.x+1
            return;
          }

        props.setPurchases([...props.purchases, {
            name: props.name,
            info: dia+' мм'+x+' шт'+'Видов: '+kinds+pri+mat+lam+sli+props.data,
            x: 1,
            price: Math.round(unitPrice)*x,
        }])   
    }

    return (
        <>
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Диаметр
                        <input className='constructor__form-input' type='number' required min={5} max={1000} name='dia' onChange={handleChangeDia} value={dia}></input>
                        <p className='constructor__form-input-unit'>мм</p>
                    </label>
                    <label className='constructor__form-label'>
                        Количество
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <label className='constructor__form-label'>
                        Кол-во видов
                        <select className='constructor__form-select constructor__form-select_small' name='kinds' onChange={handleChangeKinds}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Печать
                        <select className='constructor__form-select' name='print' onChange={handleChangePrint}>
                            <option value="1">Струйная экосольвентная</option>
                            <option value="1.1">Лазерная печать</option>
                            <option value="1.2">УФ печать (белым цветом)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">белая матовая пленка</option>
                            <option value="1.1">белая глянцевая пленка</option>
                            <option value="1.2">прозрачная матовая пленка</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">без ламинации</option>
                            <option value="1.1">Матовая (25 мкр)</option>
                            <option value="1.2">Глянцевая (25 мкр)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Нарезка
                        <select className='constructor__form-select' name='slicing' onChange={handleChangeSlicing}>
                            <option value="1">на общей подложке 300х800 мм</option>
                            <option value="1.1">на общей подложке 330х487 мм</option>
                            <option value="1.2">ручная нарезка</option>
                        </select>
                    </label>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/шт</p>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
                </div>
            </form>
        </>
    );
  }
  
  export default StickerForm;