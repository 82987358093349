import React from 'react';

function BannerForm(props) {

    const [unitPrice, setUnitPrice] = React.useState(5);
    const [w, setW] = React.useState(5);
    const [h, setH] = React.useState(5);
    const [WH, setWH] = React.useState(5);
    const [x, setX] = React.useState(1);
    const [material, setMaterial] = React.useState(1);
    const [resolution, setResolution] = React.useState(1);
    const [grommets, setGrommets] = React.useState(1);
    const [sizing, setSizing] = React.useState(false);

    function handleChangeW(e) {
        setW(e.target.value);
        const newWH = (h*e.target.value) / ((Number(h)+Number(e.target.value))/2);
        setWH(newWH);
        setUnitPrice(newWH*unitPrice/WH)
    }

    function handleChangeH(e) {
        setH(e.target.value);
        const newWH = (w*e.target.value) / ((Number(w)+Number(e.target.value))/2)
        setWH(newWH);
        setUnitPrice(newWH*unitPrice/WH)
    }

    function handleChangeX(e) {
        setX(e.target.value);
    }

    function handleChangeMaterial(e) {
        setMaterial(e.target.value);
        setUnitPrice(e.target.value*unitPrice/material)
    }

    function handleChangeResolution(e) {
        setResolution(e.target.value);
        setUnitPrice(e.target.value*unitPrice/resolution)
    }

    function handleChangeGrommets(e) {
        setGrommets(e.target.value);
        setUnitPrice(e.target.value*unitPrice/grommets)
    }

    function handleChangeSizing(e) {
        if (sizing) {
            setSizing(false)
            setUnitPrice(unitPrice/1.5)
        } else {
            setSizing(true)
            setUnitPrice(1.5*unitPrice)
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        props.setTotal(props.total + Math.round(unitPrice)*x)

        let mat = ''
        let res = ''
        let gro = ''

        if (Number(material) === 1) {
            mat = 'Баннер литой FRONTLIT 510 гр.'
          } else if (Number(material) === 1.1) {
            mat = 'Баннерная сетка 370 гр.'
          } else {
            mat = 'Баннер светоблокирующий BLACKOUT 440 гр.'
          }

        if (Number(resolution) === 1) {
            res = '360 dpi'
          } else if (Number(resolution) === 1.1) {
            res = '720 dpi'
          } else {
            res = '1080 dpi'
          }

        if (props.data !== 'Баннеры') {
            gro = ''
          } else if (Number(grommets) === 1) {
            gro = 'Люверсов нет'
          } else if (Number(grommets) === 1.1) {
            gro = 'Люверсы по углам'
          } else {
            gro = 'Люверсы 50 см'
          }

        let siz = ''
        if(sizing) {
            siz = 'Проклейка'
        }
          
          if(props.purchases.some((item)=>{return item.info === w+'х'+h+' мм'+x+' шт'+props.data+mat+res+gro+siz && item.name === props.name})) {
            let a = props.purchases.find((item)=>{return item.info === w+'х'+h+' мм'+x+' шт'+props.data+mat+res+gro+siz && item.name === props.name})
            a.x = a.x+1
            return;
          }

        props.setPurchases([...props.purchases, {
            name: props.name,
            info: w+'х'+h+' мм'+x+' шт'+props.data+mat+res+gro+siz,
            x: 1,
            price: Math.round(unitPrice)*x,
        }])   
    }

    React.useEffect(() => {
        setUnitPrice(5);
        setW(5);
        setH(5);
        setWH(5);
        setX(1);
        setMaterial(1);
        setResolution(1);
        setGrommets(1);
        setSizing(false);
        document.getElementsByClassName('constructor__form')[0].reset();
    }, [props.data]);

    return (
        <>
        { props.data === 'Баннеры'
        ?
        <form className="constructor__form" onSubmit={handleSubmit}>
            <div className="constructor__form-inputs">
                <label className='constructor__form-label'>
                    Размер (ШхВ)
                    <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                    <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                    <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                    <p className='constructor__form-input-multiplier'>X</p>
                </label>
                <label className='constructor__form-label'>
                    Количество
                    <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                    <p className='constructor__form-input-unit'>шт</p>
                </label>
                <label className='constructor__form-label'>
                    Материал
                    <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                        <option value="1">Баннер литой FRONTLIT 510 гр.</option>
                        <option value="1.1">Баннерная сетка 370 гр.</option>
                        <option value="1.2">Баннер светоблокирующий BLACKOUT 440 гр.</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Разрешение
                    <select className='constructor__form-select' name='lamination' onChange={handleChangeResolution}>
                        <option value="1">360 dpi</option>
                        <option value="1.1">720 dpi</option>
                        <option value="1.2">1080 dpi</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Люверсы
                    <select className='constructor__form-select' name='lamination' onChange={handleChangeGrommets}>
                        <option value="1">нет</option>
                        <option value="1.1">по углам</option>
                        <option value="1.2">50 см</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Проклейка
                    <input className='constructor__form-input_checkbox' type='checkbox' name='hard' onChange={handleChangeSizing} value={sizing}></input>
                </label>
            </div>
            <div className='constructor__form-submit-container'>
                <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/м2</p>
                <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                <button type='submit' className='constructor__form-submit'>
                    Добавить в корзину</button>
                <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
            </div>
        </form>
        :
        <form className="constructor__form" onSubmit={handleSubmit}>
            <div className="constructor__form-inputs">
                <label className='constructor__form-label'>
                    Размер (ШхВ)
                    <input className='constructor__form-input' type='number' required min={5} max={1000} name='w' onChange={handleChangeW} value={w}></input>
                    <input className='constructor__form-input constructor__form-input_x ' type='number' required min={5} max={1000} name='h' onChange={handleChangeH} value={h}></input>
                    <p className='constructor__form-input-unit constructor__form-input-unit_x'>мм</p>
                    <p className='constructor__form-input-multiplier'>X</p>
                </label>
                <label className='constructor__form-label'>
                    Количество
                    <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                    <p className='constructor__form-input-unit'>шт</p>
                </label>
                <label className='constructor__form-label'>
                    Материал
                    <select className='constructor__form-select' name='material' onChange={handleChangeMaterial}>
                        <option value="1">Баннер литой FRONTLIT 510 гр.</option>
                        <option value="1.1">Баннерная сетка 370 гр.</option>
                        <option value="1.2">Баннер светоблокирующий BLACKOUT 440 гр.</option>
                    </select>
                </label>
                <label className='constructor__form-label'>
                    Разрешение
                    <select className='constructor__form-select' name='lamination' onChange={handleChangeResolution}>
                        <option value="1">360 dpi</option>
                        <option value="1.1">720 dpi</option>
                        <option value="1.2">1080 dpi</option>
                    </select>
                </label>
            </div>
            <div className='constructor__form-submit-container'>
                <p className='constructor__form-unit-price'>{Math.round(unitPrice)} руб/м2</p>
                <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                <button type='submit' className='constructor__form-submit'>
                    Добавить в корзину</button>
                <p className='constructor__form-link' href='#'>Нужен дизайн наклейки?</p>
            </div>
        </form>
        }
        </>
    );
  }
  
  export default BannerForm;