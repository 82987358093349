import { Link } from 'react-router-dom';

function Nav(props) {
    return (
      <>
        <div className='nav'>
            <div className='nav-container'>
                <Link className='nav-link' to="/">Типография</Link>
                <p className='nav-text'>/</p>
                <p className='nav-text'>{props.name}</p>
            </div>
        </div>
      </>
    );
  }
  
  export default Nav;