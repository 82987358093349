import pen from '../../images/pen.png'
import bookB from '../../images/bookB.png'
import bookFront from '../../images/bookFront.png'
import React from 'react';
import { useLocation } from 'react-router-dom';

function CataligForm(props) {

    const [unitPrice, setUnitPrice] = React.useState(5);
    const [dia, setDia] = React.useState(5);
    const [x, setX] = React.useState(1);
    const [print, setPrint] = React.useState(1);
    const [print2, setPrint2] = React.useState(1);
    const [material, setMaterial] = React.useState(1);
    const [material2, setMaterial2] = React.useState(1);
    const [lamination, setLamination] = React.useState(1);
    const [chroma, setСhroma] = React.useState(1);
    const [chroma2, setСhroma2] = React.useState(1);

    const popups = document.getElementsByClassName('constructor__form-popup')

    const url = useLocation().pathname;

    function handleChangeDia(e) {
        setDia(e.target.value);
        setUnitPrice(e.target.value*unitPrice/dia)
        if (e.target.value === '5') {
            props.setW(70)
            props.setH(99)
        } else if (e.target.value === '6') {
            props.setW(80)
            props.setH(109)
        } else {
            props.setW(90)
            props.setH(119)
        }
      }

    function handleChangeX(e) {
        setX(e.target.value);
    }

    function handleChangePrint(e) {
        setPrint(e.target.value);
        setUnitPrice(e.target.value*unitPrice/print)
    }

    function handleChangePrint2(e) {
        setPrint2(e.target.value);
        setUnitPrice(unitPrice+Number(e.target.value)-print2)
    }

    function handleChangeMaterial(e) {
        setMaterial(e.target.value);
        setUnitPrice(e.target.value*unitPrice/material)
    }

    function handleChangeMaterial2(e) {
        setMaterial2(e.target.value);
        setUnitPrice(e.target.value*unitPrice/material2)
    }

    function handleChangeLamination(e) {
        setLamination(e.target.value);
        setUnitPrice(e.target.value*unitPrice/lamination)
    }

    function handleChangeСhroma(e) {
        setСhroma(e.target.value);
        setUnitPrice(e.target.value*unitPrice/chroma)
    }

    function handleChangeСhroma2(e) {
        setСhroma2(e.target.value);
        setUnitPrice(e.target.value*unitPrice/chroma2)
    }

    function handleSubmit(e) {
        e.preventDefault()
        props.setTotal(props.total + Math.round(unitPrice)*x)

        let pri = ''
        let pri2 = print2+" стр"
        let mat = ''
        let mat2 = ''
        let lam = ''
        let chr = ''
        let chr2 = ''

        if (url === "/pechat-prezentaciy" || url === "/bloknoty") {
            pri = ''
        } else if (Number(print) === 1) {
            pri = 'Струйная экосольвентная'
        } else if (Number(print) === 1.1) {
            pri = 'Лазерная печать'
        } else {
            pri = 'УФ печать (белым цветом)'
        }

        if (url === "/pechat-katalogov") {
            pri2 = ''
        } 

        if (Number(material) === 1) {
            mat = 'офсетка 80 г/м²'
        } else if (Number(material) === 1.1) {
            mat = 'меловка 130 г/м²'
        } else {
            mat = 'прозрачная матовая пленка'
        }

        if (Number(material2) === 1) {
            mat2 = 'офсетка 80 г/м'
        } else if (Number(material2) === 1.1) {
            mat2 = 'меловка 130 г/м²'
        } else {
            mat2 = 'меловка 300 г/м²'
        }

        if (Number(lamination) === 1) {
            lam = 'без ламинации'
        } else if (Number(lamination) === 1.1) {
            lam = 'глянцевая ламинация 32мкр.'
        } else {
            lam = 'матовая ламинация 75мкр.'
        }

        if (Number(chroma) === 1) {
            chr = '4+0'
        } else {
            chr = '4+4'
        }

        if (Number(chroma2) === 1) {
            chr2 = '4+0'
        } else {
            chr2 = '4+4'
        }
        
        if(props.purchases.some((item)=>{return item.info === dia+' мм'+x+' шт'+pri+pri2+props.data+'обложка: '+mat+chr+lam+',блок: '+mat2+chr2 && item.name === props.name})) {
            let a = props.purchases.find((item)=>{return item.info === dia+' мм'+x+' шт'+pri+pri2+props.data+'обложка: '+mat+chr+lam+',блок: '+mat2+chr2 && item.name === props.name})
            a.x = a.x+1
            return;
        }

        props.setPurchases([...props.purchases, {
            name: props.name,
            info: dia+' мм'+x+' шт'+pri+pri2+props.data+'обложка: '+mat+chr+lam+',блок: '+mat2+chr2,
            x: 1,
            price: Math.round(unitPrice)*x,
        }])  
    }

    function handleOpenPopup(popup) {
        popup.classList.add('constructor__form-popup_active');
    }

    function handleClosePopups() {
        document.getElementsByClassName('constructor__form-popup_active')[0].classList.remove('constructor__form-popup_active')
    }

    React.useEffect(() => {
        setUnitPrice(5);
        setDia(5);
        setX(1);
        setPrint(1);
        setPrint2(1);
        setMaterial(1);
        setMaterial2(1);
        setLamination(1);
        setСhroma(1);
        setСhroma2(1);
        document.getElementsByClassName('constructor__form')[0].reset();
    }, [props.data]);

    return (
        <>
        {url === "/pechat-katalogov"
        ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Формат (размер)
                        <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeDia}>
                            <option value="5">A6, книга (105х148мм)</option>
                            <option value="6">A5, книга (148х210мм)</option>
                            <option value="7">A4, книга (210х297мм)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Количество страниц
                        <select className='constructor__form-select' name='print' onChange={handleChangePrint}>
                            <option value="1">Струйная экосольвентная</option>
                            <option value="1.1">Лазерная печать</option>
                            <option value="1.2">УФ печать (белым цветом)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Тираж
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <div className='constructor__form-covers'>
                        <div className='constructor__form-cover'>
                            <h3 className='constructor__form-cover-title'>Обложка</h3>
                            <button className='constructor__form-cover-btn' onClick={()=>{handleOpenPopup(popups[0])}}>
                                <p className='constructor__form-cover-info'>{Number(material) === 1 ? 'офсетка 80 г/м²' : Number(material) === 1.1 ? 'меловка 130 г/м²' : 'меловка 300 г/м²'};</p>
                                <p className='constructor__form-cover-info'>{Number(chroma) === 1 ? '4+0' : '4+4'}</p>
                                <p className='constructor__form-cover-info'>{Number(lamination) === 1 ? 'Без ламинации' : Number(lamination) === 1.1 ? 'глянцевая ламинация 32мкр.' : 'матовая ламинация 75мкр.'};</p>
                                <img className='constructor__form-cover-btn-pen' src={pen} alt='pen'></img>
                                <img className='constructor__form-cover-btn-img' src={bookB} alt='book'></img>
                            </button>
                        </div>
                        <div className='constructor__form-cover'>
                            <h3 className='constructor__form-cover-title'>Блок</h3>
                            <button className='constructor__form-cover-btn' onClick={()=>{handleOpenPopup(popups[1])}}>
                                <p className='constructor__form-cover-info'>{Number(material2) === 1 ? 'офсетка 80 г/м²' : Number(material2) === 1.1 ? 'меловка 130 г/м²' : 'меловка 300 г/м²'}</p>
                                <p className='constructor__form-cover-info'>{Number(chroma2) === 1 ? '4+0' : '4+4'}</p>
                                <p className='constructor__form-cover-info'>Без отделки;</p>
                                <img className='constructor__form-cover-btn-pen' src={pen} alt='pen'></img>
                                <img className='constructor__form-cover-btn-img' src={bookFront} alt='bookFront'></img>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн каталога?</p>
                </div>
                <div className='constructor__form-popup'>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">офсетка 80 г/м²</option>
                            <option value="1.1">меловка 130 г/м²</option>
                            <option value="1.2">меловка 300 г/м²</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Цветность
                        <select className='constructor__form-select constructor__form-select' name='chroma' onChange={handleChangeСhroma}>
                            <option value="1">4+0</option>
                            <option value="1.2">4+4</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">Без ламинации</option>
                            <option value="1.1">глянцевая ламинация 32мкр.</option>
                            <option value="1.2">матовая ламинация 75мкр.</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Тиснение
                        <select className='constructor__form-select constructor__form-select' name='Embossing' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Лак
                        <select className='constructor__form-select constructor__form-select' name='lacquer' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <div className='constructor__form-popup-container'>
                        <img className='constructor__form-popup-img' src={bookB} alt='book'></img>
                        <p className='constructor__form-popup-name'>Обложка</p>
                        <button className='constructor__form-popup-pick'onClick={()=>{handleClosePopups()}}>Выбрать</button>
                    </div>
                    <button className='constructor__form-popup-close' onClick={()=>{handleClosePopups()}}>X</button>
                </div>
                <div className='constructor__form-popup'>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select constructor__form-select' name='material' onChange={handleChangeMaterial2}>
                            <option value="1">офсетка 80 г/м²</option>
                            <option value="1.1">меловка 130 г/м²</option>
                            <option value="1.2">меловка 300 г/м²</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Цветность
                        <select className='constructor__form-select constructor__form-select' name='chroma' onChange={handleChangeСhroma2}>
                            <option value="1">4+0</option>
                            <option value="1.2">4+4</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select constructor__form-select' name='lamination' disabled>
                            <option value="1">Без ламинации</option>
                            <option value="1.1">глянцевая ламинация 32мкр.</option>
                            <option value="1.2">матовая ламинация 75мкр.</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Тиснение
                        <select className='constructor__form-select constructor__form-select' name='Embossing' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Лак
                        <select className='constructor__form-select constructor__form-select' name='lacquer' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <div className='constructor__form-popup-container'>
                        <img className='constructor__form-popup-img' src={bookFront} alt='book'></img>
                        <p className='constructor__form-popup-name'>Блок</p>
                        <button className='constructor__form-popup-pick' onClick={()=>{handleClosePopups()}}>Выбрать</button>
                    </div>
                    <button className='constructor__form-popup-close' onClick={()=>{handleClosePopups()}}>X</button>
                </div>
            </form>
        : url === "/pechat-prezentaciy"
        ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Формат (размер)
                        <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeDia}>
                            <option value="5">A6, книга (105х148мм)</option>
                            <option value="6">A5, книга (148х210мм)</option>
                            <option value="7">A4, книга (210х297мм)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Количество страниц
                        <input className='constructor__form-input' type='number' required min={1} max={100} name='print' onChange={handleChangePrint2} value={print2}></input>
                        <p className='constructor__form-input-unit'>стр</p>
                    </label>
                    <label className='constructor__form-label'>
                        Тираж
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <div className='constructor__form-covers'>
                        <div className='constructor__form-cover'>
                            <h3 className='constructor__form-cover-title'>Обложка</h3>
                            <button className='constructor__form-cover-btn' onClick={()=>{handleOpenPopup(popups[0])}}>
                                <p className='constructor__form-cover-info'>{Number(material) === 1 ? 'офсетка 80 г/м²' : Number(material) === 1.1 ? 'меловка 130 г/м²' : 'меловка 300 г/м²'};</p>
                                <p className='constructor__form-cover-info'>{Number(chroma) === 1 ? '4+0' : '4+4'}</p>
                                <p className='constructor__form-cover-info'>{Number(lamination) === 1 ? 'Без ламинации' : Number(lamination) === 1.1 ? 'глянцевая ламинация 32мкр.' : 'матовая ламинация 75мкр.'};</p>
                                <img className='constructor__form-cover-btn-pen' src={pen} alt='pen'></img>
                                <img className='constructor__form-cover-btn-img' src={bookB} alt='book'></img>
                            </button>
                        </div>
                        <div className='constructor__form-cover'>
                            <h3 className='constructor__form-cover-title'>Блок</h3>
                            <button className='constructor__form-cover-btn' onClick={()=>{handleOpenPopup(popups[1])}}>
                                <p className='constructor__form-cover-info'>{Number(material2) === 1 ? 'офсетка 80 г/м²' : Number(material2) === 1.1 ? 'меловка 130 г/м²' : 'меловка 300 г/м²'}</p>
                                <p className='constructor__form-cover-info'>{Number(chroma2) === 1 ? '4+0' : '4+4'}</p>
                                <p className='constructor__form-cover-info'>Без отделки;</p>
                                <img className='constructor__form-cover-btn-pen' src={pen} alt='pen'></img>
                                <img className='constructor__form-cover-btn-img' src={bookFront} alt='bookFront'></img>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн каталога?</p>
                </div>
                <div className='constructor__form-popup'>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">офсетка 80 г/м²</option>
                            <option value="1.1">меловка 130 г/м²</option>
                            <option value="1.2">меловка 300 г/м²</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Цветность
                        <select className='constructor__form-select constructor__form-select' name='chroma' onChange={handleChangeСhroma}>
                            <option value="1">4+0</option>
                            <option value="1.2">4+4</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">Без ламинации</option>
                            <option value="1.1">глянцевая ламинация 32мкр.</option>
                            <option value="1.2">матовая ламинация 75мкр.</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Тиснение
                        <select className='constructor__form-select constructor__form-select' name='Embossing' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Лак
                        <select className='constructor__form-select constructor__form-select' name='lacquer' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <div className='constructor__form-popup-container'>
                        <img className='constructor__form-popup-img' src={bookB} alt='book'></img>
                        <p className='constructor__form-popup-name'>Обложка</p>
                        <button className='constructor__form-popup-pick'onClick={()=>{handleClosePopups()}}>Выбрать</button>
                    </div>
                    <button className='constructor__form-popup-close' onClick={()=>{handleClosePopups()}}>X</button>
                </div>
                <div className='constructor__form-popup'>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select constructor__form-select' name='material' onChange={handleChangeMaterial2}>
                            <option value="1">офсетка 80 г/м²</option>
                            <option value="1.1">меловка 130 г/м²</option>
                            <option value="1.2">меловка 300 г/м²</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Цветность
                        <select className='constructor__form-select constructor__form-select' name='chroma' onChange={handleChangeСhroma2}>
                            <option value="1">4+0</option>
                            <option value="1.2">4+4</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select constructor__form-select' name='lamination' disabled>
                            <option value="1">Без ламинации</option>
                            <option value="1.1">глянцевая ламинация 32мкр.</option>
                            <option value="1.2">матовая ламинация 75мкр.</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Тиснение
                        <select className='constructor__form-select constructor__form-select' name='Embossing' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Лак
                        <select className='constructor__form-select constructor__form-select' name='lacquer' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <div className='constructor__form-popup-container'>
                        <img className='constructor__form-popup-img' src={bookFront} alt='book'></img>
                        <p className='constructor__form-popup-name'>Блок</p>
                        <button className='constructor__form-popup-pick' onClick={()=>{handleClosePopups()}}>Выбрать</button>
                    </div>
                    <button className='constructor__form-popup-close' onClick={()=>{handleClosePopups()}}>X</button>
                </div>
            </form>
        : url === "/bloknoty"
        ?
            <form className="constructor__form" onSubmit={handleSubmit}>
                <div className="constructor__form-inputs">
                    <label className='constructor__form-label'>
                        Формат (размер)
                        <select className='constructor__form-select constructor__form-select' name='kinds' onChange={handleChangeDia}>
                            <option value="5">A6, книга (105х148мм)</option>
                            <option value="6">A5, книга (148х210мм)</option>
                            <option value="7">A4, книга (210х297мм)</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Количество страниц
                        <input className='constructor__form-input' type='number' required min={1} max={100} name='print' onChange={handleChangePrint2} value={print2}></input>
                        <p className='constructor__form-input-unit'>стр</p>
                    </label>
                    <label className='constructor__form-label'>
                        Тираж
                        <input className='constructor__form-input' type='number' required min={1} max={1000} name='x' onChange={handleChangeX} value={x}></input>
                        <p className='constructor__form-input-unit'>шт</p>
                    </label>
                    <div className='constructor__form-covers'>
                        <div className='constructor__form-cover'>
                            <h3 className='constructor__form-cover-title'>Обложка</h3>
                            <button className='constructor__form-cover-btn' onClick={()=>{handleOpenPopup(popups[0])}}>
                                <p className='constructor__form-cover-info'>{Number(material) === 1 ? 'офсетка 80 г/м²' : Number(material) === 1.1 ? 'меловка 130 г/м²' : 'меловка 300 г/м²'};</p>
                                <p className='constructor__form-cover-info'>{Number(chroma) === 1 ? '4+0' : '4+4'}</p>
                                <p className='constructor__form-cover-info'>{Number(lamination) === 1 ? 'Без ламинации' : Number(lamination) === 1.1 ? 'глянцевая ламинация 32мкр.' : 'матовая ламинация 75мкр.'};</p>
                                <img className='constructor__form-cover-btn-pen' src={pen} alt='pen'></img>
                                <img className='constructor__form-cover-btn-img' src={bookB} alt='book'></img>
                            </button>
                        </div>
                        <div className='constructor__form-cover'>
                            <h3 className='constructor__form-cover-title'>Блок</h3>
                            <button className='constructor__form-cover-btn' onClick={()=>{handleOpenPopup(popups[1])}}>
                                <p className='constructor__form-cover-info'>{Number(material2) === 1 ? 'офсетка 80 г/м²' : Number(material2) === 1.1 ? 'меловка 130 г/м²' : 'меловка 300 г/м²'}</p>
                                <p className='constructor__form-cover-info'>{Number(chroma2) === 1 ? '4+0' : '4+4'}</p>
                                <p className='constructor__form-cover-info'>Без отделки;</p>
                                <img className='constructor__form-cover-btn-pen' src={pen} alt='pen'></img>
                                <img className='constructor__form-cover-btn-img' src={bookFront} alt='bookFront'></img>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='constructor__form-submit-container'>
                    <p className='constructor__form-total-price'>{Math.round(unitPrice)*x} руб</p>
                    <button type='submit' className='constructor__form-submit'>
                        Добавить в корзину</button>
                    <p className='constructor__form-link' href='#'>Нужен дизайн каталога?</p>
                </div>
                <div className='constructor__form-popup'>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select constructor__form-select' name='material' onChange={handleChangeMaterial}>
                            <option value="1">офсетка 80 г/м²</option>
                            <option value="1.1">меловка 130 г/м²</option>
                            <option value="1.2">меловка 300 г/м²</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Цветность
                        <select className='constructor__form-select constructor__form-select' name='chroma' onChange={handleChangeСhroma}>
                            <option value="1">4+0</option>
                            <option value="1.2">4+4</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select constructor__form-select' name='lamination' onChange={handleChangeLamination}>
                            <option value="1">Без ламинации</option>
                            <option value="1.1">глянцевая ламинация 32мкр.</option>
                            <option value="1.2">матовая ламинация 75мкр.</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Тиснение
                        <select className='constructor__form-select constructor__form-select' name='Embossing' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Лак
                        <select className='constructor__form-select constructor__form-select' name='lacquer' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <div className='constructor__form-popup-container'>
                        <img className='constructor__form-popup-img' src={bookB} alt='book'></img>
                        <p className='constructor__form-popup-name'>Обложка</p>
                        <button className='constructor__form-popup-pick'onClick={()=>{handleClosePopups()}}>Выбрать</button>
                    </div>
                    <button className='constructor__form-popup-close' onClick={()=>{handleClosePopups()}}>X</button>
                </div>
                <div className='constructor__form-popup'>
                    <label className='constructor__form-label'>
                        Материал
                        <select className='constructor__form-select constructor__form-select' name='material' onChange={handleChangeMaterial2}>
                            <option value="1">офсетка 80 г/м²</option>
                            <option value="1.1">меловка 130 г/м²</option>
                            <option value="1.2">меловка 300 г/м²</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Цветность
                        <select className='constructor__form-select constructor__form-select' name='chroma' onChange={handleChangeСhroma2}>
                            <option value="1">4+0</option>
                            <option value="1.2">4+4</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Ламинация
                        <select className='constructor__form-select constructor__form-select' name='lamination' disabled>
                            <option value="1">Без ламинации</option>
                            <option value="1.1">глянцевая ламинация 32мкр.</option>
                            <option value="1.2">матовая ламинация 75мкр.</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Тиснение
                        <select className='constructor__form-select constructor__form-select' name='Embossing' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <label className='constructor__form-label'>
                        Лак
                        <select className='constructor__form-select constructor__form-select' name='lacquer' disabled>
                            <option value="1">без отделки</option>
                        </select>
                    </label>
                    <div className='constructor__form-popup-container'>
                        <img className='constructor__form-popup-img' src={bookFront} alt='book'></img>
                        <p className='constructor__form-popup-name'>Блок</p>
                        <button className='constructor__form-popup-pick' onClick={()=>{handleClosePopups()}}>Выбрать</button>
                    </div>
                    <button className='constructor__form-popup-close' onClick={()=>{handleClosePopups()}}>X</button>
                </div>
            </form>
        :
        <h1>no</h1>
        }
        </>
    );
  }
  
  export default CataligForm;