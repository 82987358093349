import businessCard from '../../images/CP-business-card-icon.svg'
import sticker from '../../images/cp-sticker-special-icon.svg'
import leaflet from '../../images/CP-leaflet-icon.svg'
import booklet from '../../images/CP-booklet-icon.svg'
import flyer from '../../images/CP-flyer-icon.svg'
import catalog from '../../images/CP-catalog-icon.svg'
import advert from '../../images/CP-advert-icon.svg'
import postcard from '../../images/CP-postcard-icon.svg'
import poster from '../../images/CP-poster-icon.svg'
import blank from '../../images/CP-blank-icon.svg'
import calendar from '../../images/CP-calendar-quarter-3-spring-icon.svg'
import presentation from '../../images/CP-catalog-presentation-icon.svg'
import banner from '../../images/CP-banner-icon.svg'
import blueprint from '../../images/CP-blueprint-icon.svg'
import envelope from '../../images/CP-envelope-icon.svg'
import notebook from '../../images/CP-catalog-notebook-icon.svg'
import clamp from '../../images/CP-catalog-clamp-icon.svg'
import slice from '../../images/slice-help-1.svg'
import invitation from '../../images/CP-invitation-icon.svg'
import { useNavigate } from 'react-router-dom';
import React from 'react';

function PrintingHouse() {

    const navigate = useNavigate();
    const data = [
        {
            src: businessCard, 
            name: 'Визитки',
            price: 'от 409 руб',
            url: 'vizitki',
        },
        {
            src: sticker, 
            name: 'Наклейки',
            price: 'от 350 руб',
            url: 'pechat-nakleek',
        },
        {
            src: leaflet, 
            name: 'Листовки',
            price: 'от 1019 руб',
            url: 'pechat-listovok',
        },
        {
            src: booklet, 
            name: 'Буклеты',
            price: 'от 2435 руб',
            url: 'pechat-bukletov',
        },
        {
            src: flyer, 
            name: 'Флаеры',
            price: 'от 607 руб',
            url: 'pechat-flaerov',
        },
        {
            src: catalog, 
            name: 'Каталоги',
            price: 'от 2889 руб',
            url: 'pechat-katalogov',
        },
        {
            src: advert, 
            name: 'Объявления',
            price: 'от 1019 руб',
            url: 'pechat-obyavleniy',
        },
        {
            src: postcard, 
            name: 'Открытки',
            price: 'от 789 руб',
            url: 'pechat-otkritok',
        },
        {
            src: poster, 
            name: 'Плакаты',
            price: 'от 440 руб',
            url: 'pechat-plakatov',
        },
        {
            src: blank, 
            name: 'Бланки',
            price: 'от 1402 руб',
            url: 'pechat-blankov',
        },
        {
            src: calendar, 
            name: 'Календари',
            price: 'от 990 руб',
            url: 'pechat-kalendarey',
        },
        {
            src: presentation, 
            name: 'Презентации',
            price: '',
            url: 'pechat-prezentaciy',
        },
        {
            src: banner, 
            name: 'Баннеры',
            price: 'от 990 руб',
            url: 'pechat-bannerov',
        },
        {
            src: blueprint, 
            name: 'Чертежи',
            price: 'от 360 руб',
            url: 'pechat-chertezhej',
        },
        {
            src: envelope, 
            name: 'Конверты',
            price: 'от 1082 руб',
            url: 'pechat-konvertov',
        },
        {
            src: notebook, 
            name: 'Блокноты',
            price: 'от 2889 руб',
            url: 'bloknoty',
        },
        // {
        //     src: clamp, 
        //     name: 'Брошюры',
        //     price: 'от 2889 руб',
        //     url: 'pechat-broshyur',
        // },
        {
            src: slice, 
            name: 'Этикетки',
            price: 'от 920 руб',
            url: 'pechat-etiketok',
        },
        {
            src: invitation, 
            name: 'Приглашения',
            price: 'от 789 руб',
            url: 'priglasitelnye',
        },

    ]

    function Redirect(way) {
        navigate(`/${way}`);
    }

    return (
      <main className="printing-house">
        {data.map((article)=>{
            return (
                <div className="printing-house__article">
                    <img className="printing-house__article-image" src={article.src} alt={article.src} onClick={()=>{Redirect(article.url)}}></img>
                    <p className="printing-house__article-name" onClick={()=>{Redirect(article.url)}} >{article.name}</p>
                    <p className="printing-house__article-price">{article.price}</p>
                </div>
            )
        })}
      </main>
    );
  }
  
  export default PrintingHouse;