import orafol3 from '../../images/orafol3.svg'
import upm1 from '../../images/upm1.svg'

import quat from '../../images/3quat_1.svg'
import quat2 from '../../images/1quat_1.svg'
import dom from '../../images/dom_p_5.png'
import dom2 from '../../images/dom_s_2.png'
import pocket from '../../images/pocket.svg'
import upmperekid from '../../images/perekid.svg'

import React from 'react';
import { useLocation } from 'react-router-dom';

function Info(props) {

    const url = useLocation().pathname;

    return (
        <>
        {url === "/pechat-nakleek" || url === "/pechat-etiketok"
        ?
            <>
                { props.data === 'Наборы стикеров'
                ?
                <div className='info'>
                    <p className='info__text'>
                        Для печати наклеек мы используем японское оборудование компании Mimaki и KM
                    </p>
                    <p className='info__text'>
                        Наборы стикеров отдаются листами, на листе неограниченное кол-во стикеров с насечкой по форме
                    </p>
                    <p className='info__text'>
                        Мы печатаем на бумажной самоклейке или немецких пленках компании Orafol
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 1-3 дня</p>
                </div>
                : props.data === 'Наклейки на монтажной пленке'
                ?
                <div className='info'>
                    <p className='info__text'>
                        Для печати наклеек мы используем японское оборудование компании Mimaki и KM
                    </p>
                    <p className='info__text'>
                        Печать производится на немецких пленках компании Orafol
                    </p>
                    <p className='info__text'>
                        Поверх отпечатанного и вырезанного изображения наносится монтажная пленка для простоты переноса наклейки
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 1-3 дня</p>
                </div>
                : props.data === 'Простые наклейки на бумаге'
                ?
                <div className='info'>
                    <p className='info__text'>
                        Бюджетный вариант, неустойчивый к атмосферным воздействиям и могут быть только прямоугольной формы
                    </p>
                    <p className='info__text'>
                        Печать производится на полу-глянцевой самоклеящейся бумаге
                    </p>
                    <img className='info__image2' alt='upm1' src={upm1}></img>
                </div>
                : props.data === 'Наклейки на машину'
                || props.data === 'Графика на пол'
                ?
                <div className='info'>
                    <p className='info__text'>
                        Для печати наклеек мы используем японское оборудование компании Mimaki и KM
                    </p>
                    <p className='info__text'>
                        Печать производится на немецких пленках компании Orafol
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 1-3 дня</p>
                </div>
                : props.data === 'Графика на стену'
                ?
                <div className='info'>
                    <p className='info__text'>
                        Для печати наклеек мы используем японское оборудование компании Mimaki и KM
                    </p>
                    <p className='info__text'>
                        Печать производится на немецких пленках компании Orafol с разрешением 720 DPI
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 1-3 дня</p>
                </div>
                : props.data === 'Обратные стикеры'
                ?
                <div className='info'>
                    <p className='info__text'>
                    Печать производится со стороны клеевого слоя, для того что бы данную наклейку можно было приклеить с внутренней стороны стекла. Пленка с изображением - глянцевая, оборотная сторона - белая матовая
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 1-3 дня</p>
                </div>
                : props.data === 'Объемные стикеры' || props.data === 'Обьемные этикетки'
                ?
                <div className='info'>
                    <p className='info__text'>
                        Печать производится на немецких пленках компании Orafol
                    </p>
                    <p className='info__text'>
                        Поверх наносится полиуритановая или эпоксидная смола для защиты и придания объема наклейки
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 4-5 днtq</p>
                </div>
                : props.data === 'Гарантийные стикеры' || props.data === 'Гарантийные этикетки'
                ?
                <div className='info'>
                    <p className='info__text'>
                        Печать производится на специальной хрупкой (с низкой разрывной устойчивостью) пленке ORACAL® 820 Safety Vinyl
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 1-3 дня</p>
                </div>
                : props.data === 'Черно-белые этикетки'
                ?
                <div className='info'>
                    <p className='info__text'>
                        Минимальный тираж от 3000 шт
                    </p>
                    <p className='info__text'>
                        Мы печатаем на бумажной самоклейке или немецких пленках компании Orafol
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 1-3 дня</p>
                </div>
                :
                <div className='info'>
                    <p className='info__text'>
                        Для печати наклеек мы используем японское оборудование компании Mimaki и KM
                    </p>
                    <p className='info__text'>
                        Мы печатаем на бумажной самоклейке или немецких пленках компании Orafol
                    </p>
                    <img className='info__image' alt='orafol3' src={orafol3}></img>
                    <p className='info__term'>Срок изготовления: 1-3 дня</p>
                </div>
                }
            </>
        : url === "/pechat-katalogov"
        ?
            <div className='info'>
                <div className={props.data === 'Каталог на пружине' ? 'info__first-model info__first-model_alt' : 'info__first-model'} style={{width: props.W + 'px', height: props.H + 'px'}}></div>
                <p className='info__model-text'>{`${props.W*1.5}x${Math.round(props.H*1.5-1)}мм`}</p>
                <p className='info__model-text'>Размер в готовом виде</p>
                <div className={props.data === 'Каталог на пружине' ? 'info__second-model info__second-model_alt1' : props.data === 'Каталог на клею (КБС)' ? 'info__second-model info__second-model_alt2': 'info__second-model'} style={{width: props.W*2 + 'px', height: props.H + 'px'}}></div>
                <p className='info__model-text'>{`${props.W*1.5*2}x${Math.round(props.H*1.5-1)}мм`}</p>
                <p className='info__model-text'>Размер в развернутом виде</p>
            </div>
        : url === "/pechat-plakatov" || url === "/pechat-chertezhej"
        ?
            <div className='info'>   
            </div>
        : url === "/pechat-kalendarey"
        ?
            <div className='info'>
                { props.data === 'Квартальные календари на 3-х пружинах'
                ?
                <div className='info__image-container'> 
                    { props.W === 70 
                    ? 
                        <>
                        <img className='info__image-cal' src={quat} alt='calendar' style={{width: '95px', height: '300px'}}></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Постер<br></br>297x210 мм</p>
                            <p className='info__model-text info__model-text_cal'>Календарный блок<br></br>297x145 мм</p>
                        </div>
                        </>
                    : props.W === 80
                    ?
                        <>
                        <img className='info__image-cal' src={quat} alt='calendar' style={{width: '100px', height: '300px'}}></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Постер<br></br>335x250 мм</p>
                            <p className='info__model-text info__model-text_cal'>Календарный блок<br></br>335x160 мм</p>
                        </div>
                        </>
                    :
                        <>
                        <img className='info__image-cal' src={quat} alt='calendar' style={{width: '105px', height: '300px'}}></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Постер<br></br>370x290 мм</p>
                            <p className='info__model-text info__model-text_cal'>Календарный блок<br></br>370x170 мм</p>
                        </div>
                        </>
                    }
                </div>
                : props.data === 'Квартальные календари на 1 пружине'
                ?
                <div className='info__image-container'> 
                    { props.W === 70 
                    ? 
                        <>
                        <img className='info__image-cal' src={quat2} alt='calendar' style={{width: '95px', height: '300px'}}></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Постер<br></br>297x210 мм</p>
                            <p className='info__model-text info__model-text_cal'>Календарный блок<br></br>297x145 мм</p>
                        </div>
                        </>
                    : props.W === 80
                    ?
                        <>
                        <img className='info__image-cal' src={quat2} alt='calendar' style={{width: '100px', height: '300px'}}></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Постер<br></br>335x250 мм</p>
                            <p className='info__model-text info__model-text_cal'>Календарный блок<br></br>335x160 мм</p>
                        </div>
                        </>
                    :
                        <>
                        <img className='info__image-cal' src={quat2} alt='calendar' style={{width: '105px', height: '300px'}}></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Постер<br></br>370x290 мм</p>
                            <p className='info__model-text info__model-text_cal'>Календарный блок<br></br>370x170 мм</p>
                        </div>
                        </>
                    }
                </div>
                : props.data === 'Календари домики перекидные' 
                || props.data === 'Календари домики самосборные' 
                ?
                <div> 
                    { props.W === 70 
                    ? 
                        <>
                        <img className='info__image-cal' src={props.data === 'Календари домики перекидные' ? dom : dom2} alt='calendar'></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Календари домики перекидные' ? 'Блок 200х95 мм' : 'Сторона'}</p>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Календари домики перекидные' ? 'подложка 200х100 мм' : '210х100 мм'}</p>
                        </div>
                        </>
                    : props.W === 80
                    ?
                        <>
                        <img className='info__image-cal' src={props.data === 'Календари домики перекидные' ? dom : dom2} alt='calendar'></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Календари домики перекидные' ? 'Блок 200х95 мм' : 'Сторона'}</p>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Календари домики перекидные' ? 'подложка 200х120 мм' : '120х170 мм'}</p>
                            {props.data === 'Календари домики перекидные' ? <p className='info__model-text info__model-text_cal'>рекламное поле 200х25 мм</p> : <></>}
                        </div>
                        </>
                    :
                        <>
                        <img className='info__image-cal' src={props.data === 'Календари домики перекидные' ? dom : dom2} alt='calendar'></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Календари домики перекидные' ? 'Блок 210х130 мм' : 'Сторона'}</p>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Календари домики перекидные' ? 'подложка 210х135 мм' : '150х150 мм'}</p>
                        </div>
                        </>
                    }
                </div>
                : props.data === 'Карманные календари'
                || props.data === 'Перекидные календари'
                ?
                <div> 
                    { props.W === 70 
                    ? 
                        <>
                        <img className='info__image-cal' src={props.data === 'Карманные календари' ? pocket : upmperekid} style={{width: '170px', height: '115px'}} alt='calendar'></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Блок</p>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Карманные календари' ? '70x100 мм' : '297x210 мм'}</p>
                        </div>
                        </>
                    : props.W === 80
                    ?
                        <>
                        <img className='info__image-cal' src={props.data === 'Карманные календари' ? pocket : upmperekid} style={{width: '170px', height: '120px'}} alt='calendar'></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Блок</p>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Карманные календари' ? '100x100 мм' : '420x297 мм'}</p>
                        </div>
                        </>
                    :
                        <>
                        <img className='info__image-cal' src={props.data === 'Карманные календари' ? pocket : upmperekid} style={{width: '170px', height: '125px'}} alt='calendar'></img>
                        <div className='info__model-text-container'>
                            <p className='info__model-text info__model-text_cal'>Блок</p>
                            <p className='info__model-text info__model-text_cal'>{props.data === 'Карманные календари' ? '100x70 мм' : '594x420 мм'}</p>
                        </div>
                        </>
                    }
                </div>
                :
                <h1>no</h1>
                }
            </div>
        : url === "/pechat-prezentaciy"
        ?
            <div className='info'>
                <div className='info__first-model info__first-model_alt' style={{width: props.H + 'px', height: props.W + 'px'}}></div>
                <p className='info__model-text'>{`${props.H*1.5}x${Math.round(props.W*1.5-1)}мм`}</p>
                <p className='info__model-text'>Размер в готовом виде</p>
                <div className='info__second-model info__second-model_alt1' style={{width: props.H*2 + 'px', height: props.W + 'px'}}></div>
                <p className='info__model-text'>{`${props.H*1.5*2}x${Math.round(props.W*1.5-1)}мм`}</p>
                <p className='info__model-text'>Размер в развернутом виде</p>
            </div>
        : url === "/pechat-bannerov"
        ? 
            <>
                    { props.data === 'Баннеры'
                    ?
                    <div className='info'>
                        <p className='info__text'>
                            Минимальная стоимость заказа от 890 р.
                        </p>
                        <p className='info__text'>
                            В зависимости от выбора типа печати во вкладке "Материал"
                        </p>
                        <p className='info__text'>
                            Печать цветная (CMYK) Струйная экосольвентная или лазерная
                        </p>
                    </div>
                    :
                    <div className='info'></div>
                    }
            </>
        : url === "/bloknoty"
        ?
            <div className='info'>
                <div className='info__first-model info__first-model_alt3' style={{width: props.W + 'px', height: props.H + 'px',}}></div>
                <p className='info__model-text'>{`${props.H*1.5}x${Math.round(props.W*1.5-1)}мм`}</p>
                <p className='info__model-text'>Размер в готовом виде</p>
                <div className='info__second-model info__second-model_alt1' style={{width: props.H*2 + 'px', height: props.W + 'px'}}></div>
                <p className='info__model-text'>{`${props.H*1.5*2}x${Math.round(props.W*1.5-1)}мм`}</p>
                <p className='info__model-text'>Размер в развернутом виде</p>
            </div>
        :
        <h1>no1</h1>
        }
        </>
    );
  }
  
  export default Info;