import round from '../../images/CP2-sticker-round-icon.svg'
import rectangular from '../../images/CP2-sticker-rectangular-icon.svg'
import oval from '../../images/CP2-sticker-oval-icon.svg'
import figured from '../../images/CP2-sticker-figured-icon.svg'
import set from '../../images/CP2-sticker-set-icon.svg'
import mountingfFilm from '../../images/CP2-sticker-mounting film-icon.svg'
import paper from '../../images/CP2-sticker-paper-icon.svg'
import onCar from '../../images/CP2-sticker-on-car-icon.svg'
import onFlat from '../../images/CP2-sticker-on-flat-icon.svg'
import onWall from '../../images/CP2-sticker-on-wall-icon.svg'
import onGlass from '../../images/CP2-sticker-on-glass-icon.svg'
import selfDestroying from '../../images/CP2-sticker-self-destroying-icon.svg'
import barcode from '../../images/CP2-sticker-barcode-icon.svg'
import d3 from '../../images/CP2-sticker-3D-icon.svg'

import catalogClamp from '../../images/CP-catalog-clamp-icon.svg'
import catalogSpring from '../../images/CP-catalog-spring-icon.svg'
import catalogGlue from '../../images/CP-catalog-glue-icon.svg'

import poster from '../../images/CP-poster-icon.svg'

import quarter from '../../images/CP-calendar-quarter-3-spring-icon.svg'
import quarter2 from '../../images/CP3-calendar-quarter-1-spring-icon.svg'
import house from '../../images/CP3-calendar-house-flip-flop-icon.svg'
import house2 from '../../images/CP3-calendar-house-icon.svg'
import pocket from '../../images/CP3-calendar-pocket-icon.svg'
import flipFlop from '../../images/CP3-calendar-flip-flop-icon.svg'

import presentation from '../../images/CP-catalog-presentation-icon.svg'

import banner from '../../images/CP-banner-icon.svg'
import art from '../../images/cp-art-picture-icon.svg'

import blueprint from '../../images/CP-blueprint-icon.svg'

import notebook from '../../images/CP-catalog-notebook-icon.svg'

import React from 'react'
import { useLocation } from 'react-router-dom';

function TypeSelection(props) {

    const url = useLocation().pathname;

    const nakleiki = [
        {
            src: round, 
            name: 'Круглые наклейки с логотипом',
            price: 'от 990 руб'
        },
        {
            src: rectangular, 
            name: 'Прямоугольные стикеры',
            price: 'от 990 руб'
        },
        {
            src: oval, 
            name: 'Овальные стикеры',
            price: 'от 990 руб'
        },
        {
            src: figured, 
            name: 'Фигурные стикеры',
            price: 'от 1680 руб'
        },
        {
            src: set, 
            name: 'Наборы стикеров',
            price: 'от 1680 руб'
        },
        {
            src: mountingfFilm, 
            name: 'Наклейки на монтажной пленке',
            price: 'от 1680 руб'
        },
        {
            src: paper, 
            name: 'Простые наклейки на бумаге',
            price: 'от 350 руб'
        },
        {
            src: onCar, 
            name: 'Наклейки на машину',
            price: 'от 1680 руб'
        },
        {
            src: onFlat, 
            name: 'Графика на пол',
            price: 'от 1680 руб'
        },
        {
            src: onWall, 
            name: 'Графика на стену',
            price: 'от 890 руб'
        },
        {
            src: onGlass, 
            name: 'Обратные стикеры',
            price: 'от 1680 руб'
        },
        {
            src: d3, 
            name: 'Объемные стикеры',
            price: 'от 1680 руб'
        },
        {
            src: selfDestroying, 
            name: 'Гарантийные стикеры',
            price: 'от 1680 руб'
        },
        {
            src: barcode, 
            name: 'Черно-белые этикетки',
            price: 'от 990 руб'
        },

    ]
    const katalogi = [
        {
            src: catalogClamp, 
            name: 'Каталог на скобу',
        },
        {
            src: catalogSpring, 
            name: 'Каталог на пружине',
        },
        {
            src: catalogGlue, 
            name: 'Каталог на клею (КБС)',
        },
    ]   
    const kalendari = [
        {
            src: quarter, 
            name: 'Квартальные календари на 3-х пружинах',
            price: 'от 103 руб'
        },
        {
            src: quarter2, 
            name: 'Квартальные календари на 1 пружине',
            price: 'от 65 руб'
        },
        {
            src: house, 
            name: 'Календари домики перекидные',
            price: 'от 43 руб'
        },
        {
            src: house2, 
            name: 'Календари домики самосборные',
            price: 'от 23 руб'
        },
        {
            src: pocket, 
            name: 'Карманные календари',
            price: 'от 8 руб'
        },
        {
            src: flipFlop, 
            name: 'Перекидные календари',
            price: 'от 84 руб'
        },
    ]
    const banneri = [
        {
            src: banner, 
            name: 'Баннеры',
        },
        {
            src: onWall, 
            name: 'Пленка',
        },
        {
            src: art, 
            name: 'Холст',
        },
    ]
    const etiketki = [
        {
            src: round, 
            name: 'Круглые этикетки с логотипом',
            price: 'от 990 руб'
        },
        {
            src: rectangular, 
            name: 'Прямоугольные этикетки',
            price: 'от 990 руб'
        },
        {
            src: oval, 
            name: 'Овальные этикетки',
            price: 'от 990 руб'
        },
        {
            src: figured, 
            name: 'Фигурные этикетки',
            price: 'от 1680 руб'
        },
        {
            src: d3, 
            name: 'Обьемные этикетки',
            price: 'от 1680 руб'
        },
        {
            src: selfDestroying, 
            name: 'Гарантийные этикетки',
            price: 'от 1680 руб'
        },
        {
            src: barcode, 
            name: 'Черно-белые этикетки',
            price: 'от 990 руб'
        },

    ]


    const [type, setType] = React.useState('');

    function handleChangeType(e) {
        setType(e.target)
        e.target.querySelector('.typeSelection__article__article-image').classList.toggle('typeSelection__article__article-image_active');
        e.target.querySelector('.typeSelection__article__article-name').classList.toggle('typeSelection__article__article-name_active');
        type.querySelector('.typeSelection__article__article-image').classList.toggle('typeSelection__article__article-image_active');
        type.querySelector('.typeSelection__article__article-name').classList.toggle('typeSelection__article__article-name_active');
        props.setData(e.target.getAttribute('value'))
    }

    React.useEffect(() => {
        let firstType = document.getElementsByClassName('typeSelection__article')[0]
        setType(firstType)
        firstType.querySelector('.typeSelection__article__article-image').classList.add('typeSelection__article__article-image_active');
        firstType.querySelector('.typeSelection__article__article-name').classList.add('typeSelection__article__article-name_active');
      }, []);

    return (
        <>
        {url === "/pechat-nakleek"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Печать наклеек</h1>
            <div className='typeSelection__articles'>
                {nakleiki.map((article)=>{
                    return (
                        <div className="typeSelection__article" onClick={(e)=>{handleChangeType(e)}} key={article.name} value={article.name}>
                            <img className="typeSelection__article__article-image" src={article.src} alt='article'></img>
                            <p className="typeSelection__article__article-name" >{article.name}</p>
                            <p className="typeSelection__article__article-price">{article.price}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        : url === "/pechat-katalogov"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Печать каталогов</h1>
            <div className='typeSelection__articles'>
                {katalogi.map((article)=>{
                    return (
                        <div className="typeSelection__article" onClick={(e)=>{handleChangeType(e)}} key={article.name} value={article.name}>
                            <img className="typeSelection__article__article-image" src={article.src} alt='article'></img>
                            <p className="typeSelection__article__article-name" >{article.name}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        : url === "/pechat-plakatov"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Печать плакатов</h1>
            <div className='typeSelection__articles'>
                <div className="typeSelection__article">
                    <img className="typeSelection__article__article-image" src={poster} alt='article'></img>
                    <p className="typeSelection__article__article-name">Плакаты</p>
                </div>
            </div>
        </div>
        : url === "/pechat-kalendarey"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Печать календарей</h1>
            <div className='typeSelection__articles'>
                {kalendari.map((article)=>{
                    return (
                        <div className="typeSelection__article" onClick={(e)=>{handleChangeType(e)}} key={article.name} value={article.name}>
                            <img className="typeSelection__article__article-image" src={article.src} alt='article'></img>
                            <p className="typeSelection__article__article-name" >{article.name}</p>
                            <p className="typeSelection__article__article-price">{article.price}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        : url === "/pechat-prezentaciy"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Печать презентаций</h1>
            <div className='typeSelection__articles'>
                <div className="typeSelection__article">
                    <img className="typeSelection__article__article-image" src={presentation} alt='article'></img>
                    <p className="typeSelection__article__article-name">Презентация</p>
                </div>
            </div>
        </div>
        : url === "/pechat-bannerov"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Печать баннеров</h1>
            <div className='typeSelection__articles'>
                {banneri.map((article)=>{
                    return (
                        <div className="typeSelection__article" onClick={(e)=>{handleChangeType(e)}} key={article.name} value={article.name}>
                            <img className="typeSelection__article__article-image" src={article.src} alt='article'></img>
                            <p className="typeSelection__article__article-name" >{article.name}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        : url === "/pechat-chertezhej"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Печать чертежей</h1>
            <div className='typeSelection__articles'>
                <div className="typeSelection__article">
                    <img className="typeSelection__article__article-image" src={blueprint} alt='article'></img>
                    <p className="typeSelection__article__article-name">Чертежи</p>
                </div>
            </div>
        </div>
        : url === "/bloknoty"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Блокноты</h1>
            <div className='typeSelection__articles'>
                <div className="typeSelection__article">
                    <img className="typeSelection__article__article-image" src={notebook} alt='article'></img>
                    <p className="typeSelection__article__article-name">Блокнот</p>
                </div>
            </div>
        </div>
        : url === "/pechat-etiketok"
        ?
        <div className='typeSelection'>
            <h1 className='typeSelection__title'>Печать этикеток</h1>
            <div className='typeSelection__articles'>
                {etiketki.map((article)=>{
                    return (
                        <div className="typeSelection__article" onClick={(e)=>{handleChangeType(e)}} key={article.name} value={article.name}>
                            <img className="typeSelection__article__article-image" src={article.src} alt='article'></img>
                            <p className="typeSelection__article__article-name" >{article.name}</p>
                            <p className="typeSelection__article__article-price">{article.price}</p>
                        </div>
                    )
                })}
            </div>
        </div>
        :
        <h1>NO</h1>
        }
      </>
    );
  }
  
  export default TypeSelection;