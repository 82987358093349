import StickerForm from "../Form/StickerForm";
import StickerForm2 from "../Form/StikerForm2";
import StickerForm3 from "../Form/StickerForm3";
import CataligForm from "../Form/CataligForm";
import CalendarForm from "../Form/CalendarForm";
import BannerForm from "../Form/BannerForm";
import Info from "../Info/Info";
import React from 'react';
import { useLocation } from 'react-router-dom';

function Constructor(props) {

    const url = useLocation().pathname;
    const [W, setW] = React.useState(70);
    const [H, setH] = React.useState(99);

    React.useEffect(() => {
        setW(70);
        setH(99);
    }, [props.data]);

    return (
        <>
        {url === "/pechat-nakleek"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    {props.data === 'Круглые наклейки с логотипом'
                    ?
                    <StickerForm total={props.total} setTotal={props.setTotal} data={props.data} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    :props.data === 'Прямоугольные стикеры'
                    || props.data === 'Овальные стикеры' 
                    || props.data === 'Фигурные стикеры' 
                    || props.data === 'Наклейки на монтажной пленке'
                    || props.data === 'Наклейки на машину'
                    || props.data === 'Графика на пол'
                    || props.data === 'Графика на стену'
                    || props.data === 'Обратные стикеры'
                    || props.data === 'Объемные стикеры'
                    || props.data === 'Гарантийные стикеры'
                    || props.data === 'Черно-белые этикетки'
                    ?
                    <StickerForm2 total={props.total} setTotal={props.setTotal} data={props.data} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    : props.data === 'Наборы стикеров' 
                    || props.data === 'Простые наклейки на бумаге'
                    ?
                    <StickerForm3 total={props.total} setTotal={props.setTotal} data={props.data} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    :
                    <h1>no el</h1>
                    }        
                    <Info data={props.data}/>
                </div>
            </div>
        </div>
        : url === "/pechat-katalogov"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    <CataligForm total={props.total} setTotal={props.setTotal} data={props.data} setW={setW} setH={setH} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    <Info data={props.data} H={H} W={W}/> 
                </div>
            </div>
        </div>
        : url === "/pechat-plakatov"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    <StickerForm3 total={props.total} setTotal={props.setTotal} data={props.data} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    <Info /> 
                </div>
            </div>
        </div>
        : url === "/pechat-kalendarey"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    <CalendarForm total={props.total} setTotal={props.setTotal} data={props.data} setW={setW} setH={setH} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    <Info Info data={props.data} H={H} W={W}/> 
                </div>
            </div>
        </div>
        : url === "/pechat-prezentaciy"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    <CataligForm total={props.total} setTotal={props.setTotal} data={props.data} setW={setW} setH={setH} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    <Info data={props.data} H={H} W={W}/> 
                </div>
            </div>
        </div>
        : url === "/pechat-bannerov"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    <BannerForm total={props.total} setTotal={props.setTotal} data={props.data} setW={setW} setH={setH} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    <Info data={props.data} H={H} W={W}/> 
                </div>
            </div>
        </div>
        : url === "/pechat-chertezhej"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    <StickerForm3 total={props.total} setTotal={props.setTotal} data={props.data} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    <Info /> 
                </div>
            </div>
        </div>
        : url === "/bloknoty"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    <CataligForm total={props.total} setTotal={props.setTotal} data={props.data} setW={setW} setH={setH} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    <Info data={props.data} H={H} W={W}/> 
                </div>
            </div>
        </div>
        : url === "/pechat-etiketok"
        ?
        <div className="constructor__background">
            <div className="constructor">
                <h1 className="constructor__title">{props.data}</h1>
                <div className="constructor__container">
                    {props.data === 'Круглые этикетки с логотипом'
                    ?
                    <StickerForm total={props.total} setTotal={props.setTotal} data={props.data} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    : props.data === 'Прямоугольные этикетки'
                    || props.data === 'Овальные этикетки' 
                    || props.data === 'Фигурные этикетки' 
                    || props.data === 'Обьемные этикетки'
                    || props.data === 'Гарантийные этикетки'
                    || props.data === 'Черно-белые этикетки'
                    ?
                    <StickerForm2 total={props.total} setTotal={props.setTotal} data={props.data} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
                    :
                    <h1>no el</h1>
                    }        
                    <Info data={props.data}/>
                </div>
            </div>
        </div>
        :
        <h1>NO</h1>
        }
        </>
    );
  }
  
  export default Constructor;