function Basket(props) {

function deletePurchase(purchase) {
    props.setPurchases(props.purchases.filter((item)=>{
        return item.info !== purchase.info || item.name !== purchase.name
    }))
    props.setTotal(props.total - purchase.price*purchase.x)
}

    return (
        <>
        { props.purchases[0]
        ?
        <div className="basket">
            {props.purchases.map((purchase)=>{
                return (
                    <div className="basket__purchase">
                        <button className="basket__purchase-delete" onClick={()=>{deletePurchase(purchase)}} >X</button>
                        <p className="basket__purchase-name">{purchase.name}</p>
                        <p className="basket__purchase-info">{purchase.info}</p>
                        <p className="basket__purchase-x">x<span className="basket__purchase-x-span">{purchase.x}</span></p>
                        <div className="basket__purchase-count-btns">

                            <button className="basket__purchase-count-btn" onClick={()=>{

                                props.setTotal(props.total + Number(purchase.price));
                                purchase.x = purchase.x+1;

                            }}>&#9650;</button>

                            <button className="basket__purchase-count-btn" onClick={()=>{

                                if(purchase.x === 1) {
                                    deletePurchase(purchase);
                                    props.setTotal(props.total - Number(purchase.price));
                                } else {
                                    props.setTotal(props.total - Number(purchase.price));
                                    purchase.x = purchase.x-1;
                                };

                            }}>&#9660;</button>

                        </div>
                        <p className="basket__purchase-price">{purchase.price*purchase.x} руб</p>
                    </div>
                )
            })}
            <p className="basket__total">Итого:{props.total}</p>
            <button className="basket__order" onClick={()=>{console.log(props.purchases);}}>Оформить заказ</button>
        </div>
        :
        <></>
        }
        </>
    );
  }
  
  export default Basket;