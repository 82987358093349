import Nav from "../Nav/Nav";
import TypeSelection from "../TypeSelection/TypeSelection";
import Constructor from "../Constructor/Constructor";
import React from 'react';

function SecondCalculator(props) {

  const [data, setData] = React.useState(props.data);

    return (
      <>
        <Nav name={props.name} />
        <TypeSelection setData={setData}/>
        <Constructor total={props.total} setTotal={props.setTotal} data={data} purchases={props.purchases} setPurchases={props.setPurchases} name={props.name}/>
      </>
    );
  }
  
  export default SecondCalculator;