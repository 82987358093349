function Footer() {
    return (
      <footer className="footer">
        <h1 className="footer__text">Принт</h1>
        <h2 className="footer__text">+7 495 663-73-81
info@coral-print.ru</h2>
      </footer> 
    );
  }
  
  export default Footer;