import logo from "../../images/coralprint-logo-long.svg"

function Header(props) {
    return (
      <header className="header">
        <img src={logo} className="header-logo"></img>
        <a className="header-email">info@coral-print.ru</a>
        <a className="header-phone">+7 495 663-73-81</a>
        <a className="header-call">Заказать звонок</a>
        <div className="header-minicart">
            <p className="header-text">На сумму</p>
            <p className="header-sum">{props.total} Р</p>
        </div>
      </header> 
    );
  }
  
  export default Header;